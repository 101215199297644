import Modal from 'react-bootstrap/Modal';
import {useState, useEffect, useRef} from 'react';
import {UpdateFirById} from '../api-requests/Requests';
import Button from 'react-bootstrap/Button';
import {CheckLg} from 'react-bootstrap-icons';
import {listErrorMsg} from '../helpers/ErrorMsgHelper';

export default function FirModalEdit({show, onHide, firDetail, onFirUpdate}) {
    const errRef = useRef();

    const [country, setCountry] = useState();

    const [errMsg, setErrMsg] = useState([]);

    useEffect(() => {
        if (firDetail['fir_id']) {
            setCountry(firDetail['country']);
        }
    }, [firDetail])

    const updateFir = () => {
      // eslint-disable-next-line no-lone-blocks
        {UpdateFirById(
            firDetail['fir_id'],
            country,
            errRef,
            onHide,
            onFirUpdate,
            setErrMsg,
       )}
    }

    return (
      <Modal
           show={show}
           onHide={onHide}
           size="md"
           aria-labelledby="contained-modal-title-vcenter"
           centered
       >
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                  Edit {firDetail['fir']}
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p ref={errRef} className={errMsg.length === 0 ? "offscreen" : "display-linebreak errmsg"} aria-live="assertive">
                  {listErrorMsg(errMsg, errRef)}
              </p>
              <table className={'table-modal'}>
                  <tbody>
                    <tr>
                        <td>
                            Country
                            <input
                                type='text'
                                className={'filterInput-xl'}
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                            />
                        </td>
                    </tr>
                  </tbody>
              </table>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="success" className={'modalButton'} onClick={updateFir}>
                  <CheckLg size={18}/>
              </Button>
          </Modal.Footer>
      </Modal>
    )
}
