import Modal from 'react-bootstrap/Modal';
import {useState, useEffect, useRef} from 'react';
import SearchbarDropdown from '../SearchbarDropdown';
import {GetFirs, UpdateAirportById} from '../api-requests/Requests';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {CheckLg} from 'react-bootstrap-icons';
import {listErrorMsg} from '../helpers/ErrorMsgHelper';
import {filterFirsByValue} from '../helpers/FirHelper';

export default function AirportModalEdit({show, onHide, airportDetail, onAirportUpdate}) {
    const errRef = useRef();

    const [iataCode, setIataCode] = useState();
    const [name, setName] = useState();
    const [country, setCountry] = useState();
    const [coordinates, setCoordinates] = useState();
    const [firId, setFirId] = useState();
    const [firName, setFirName] = useState();

    const [firs, setFirs] = useState([]);
    const [filteredFirs, setFilteredFirs] = useState([]);
    const [errMsg, setErrMsg] = useState([]);

    useEffect(() => {
        if (airportDetail['airport_id']) {
            setIataCode(airportDetail['iata_code']  ?? '');
            setName(airportDetail['name']);
            setCountry(airportDetail['country']);
            setCoordinates(airportDetail['coordinates'] ?? '');

            // eslint-disable-next-line no-lone-blocks
            {GetFirs(function(firs){
                setFirs(firs);
            })}
        }
    }, [airportDetail])

      useEffect(() => {
          if (firs.length !== 0) {
              filterFirsByValue(firs, airportDetail['firName'], function(filteredFir) {
                  setFirId(filteredFir[0][0]);
                  setFirName(filteredFir[0][1])
              })
          }
      // eslint-disable-next-line
      }, [firs])

    const setFirFunction = (firId) => {
        setFirId(firId);
    }

    const updateAirport = () => {
      // eslint-disable-next-line no-lone-blocks
        {UpdateAirportById(
            airportDetail['airport_id'],
            airportDetail['icao_code'],
            iataCode,
            name,
            country,
            coordinates,
            firId,
            errRef,
            onHide,
            onAirportUpdate,
            setErrMsg,
       )}
    }

    return (
      <Modal
           show={show}
           onHide={onHide}
           size="md"
           aria-labelledby="contained-modal-title-vcenter"
           centered
       >
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                  Edit {airportDetail['icao_code']}
                </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p ref={errRef} className={errMsg.length === 0 ? "offscreen" : "display-linebreak errmsg"} aria-live="assertive">
                  {listErrorMsg(errMsg, errRef)}
              </p>
              <table className={'table-modal'}>
                  <tbody>
                    <tr>
                        <td>
                            IATA Code
                            <input
                                type='text'
                                className={'filterInput-s'}
                                value={iataCode}
                                onChange={(e) => setIataCode(e.target.value)}
                            />
                        </td>
                        <td>
                            Name
                            <input
                                type='text'
                                className={'filterInput-xxl'}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Country
                            <input
                                type='text'
                                className={'filterInput-xxl'}
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                            />
                        </td>
                        <td>
                            FIR
                            <SearchbarDropdown
                                selection={filteredFirs}
                                onInputChange={(e) => filterFirsByValue(firs, e.target.value, function(filteredFirs){
                                    setFilteredFirs(filteredFirs)
                                })}
                                setPlace={setFirFunction}
                                value={firName}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            Coordinates
                            <Form.Control
                                as='textarea'
                                rows='3'
                                placeholder={'47.457762616222176, 8.556356000782307'}
                                value={coordinates}
                                onChange={(e) => setCoordinates(e.target.value)}
                            />
                        </td>
                    </tr>
                </tbody>
               </table>
           </Modal.Body>
          <Modal.Footer>
              <Button variant="success" className={'modalButton'} onClick={updateAirport}>
                  <CheckLg size={18}/>
              </Button>
          </Modal.Footer>
      </Modal>
    )
}
