import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {PencilSquare, Trash} from "react-bootstrap-icons";
import {DeleteFirById} from '../api-requests/Requests';

export default function FirModal({show, onHide, firDetail, onEdit, onDelete}) {
    const deleteFir = () => {
        DeleteFirById(firDetail['fir_id'], onHide, onDelete)
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
             <Modal.Header closeButton>
                 <Modal.Title id="contained-modal-title-vcenter">
                     {firDetail['fir']}
                 </Modal.Title>
             </Modal.Header>
             <Modal.Body>
                 <table className={'table-modal'}>
                     <tbody>
                         <tr>
                             <td>FIR</td>
                             <td>{firDetail['fir']}</td>
                         </tr>
                         <tr>
                             <td>Country</td>
                             <td>{firDetail['country']}</td>
                         </tr>
                     </tbody>
                 </table>
             </Modal.Body>
            <Modal.Footer>
                <Button className={'modalButton'} onClick={onEdit}>
                    <PencilSquare size={18}/>
                </Button>
                <Button variant="danger" className={'modalButton'} onClick={deleteFir} disabled={firDetail['used'] === 1}>
                    <Trash size={18}/>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
