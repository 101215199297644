import {useState} from 'react';
import LogbookModalFlight from './LogbookModalFlight';
import LogbookModalFlightEdit from './LogbookModalFlightEdit';

export default function LogbookTable({paginatedFlights, onFlightDelete, onFlightUpdate}) {
    const [flightDetail, setFlightDetail] = useState([]);

    const [detailModalShow, setDetailModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);

    var totalTimes = {
            'se': 0,
            'me': 0,
            'multi': 0,
            'total': 0,
            'night': 0,
            'ifr': 0,
            'pic': 0,
            'copi': 0,
            'dual': 0,
            'instructor': 0,
        },
        totalLandings = {
            'day': 0,
            'night': 0
        };

    const calcFlightTime = (item) => {
        let times = {};

        times.se = item.se.split(':');
        times.me = item.me.split(':');
        times.multi = item.multi_pilot.split(':');
        times.total = item.total_time.split(':');
        times.night = item.night_time.split(':');
        times.ifr = item.ifr_time.split(':');
        times.pic = item.pic_time.split(':');
        times.copi = item.copi_time.split(':');
        times.dual = item.dual_time.split(':');
        times.instructor = item.instructor_time.split(':');

        for (let time in times) {
            totalTimes[time] = totalTimes[time] + Number(times[time][0] * 60) + Number(times[time][1]);
        }
    }

    const calcLandings = (item) => {
        totalLandings.day = totalLandings.day + item.landings_day;
        totalLandings.night = totalLandings.night + item.landings_night;
    }

    const getHoursAndMinutes = (minutes) => {
        let hours = Math.floor(minutes / 60),
            min = minutes % 60;

        let calculatedMinutes = (min.toString().length === 1)
            ? '0' + min
            : min;

        return hours + ":" + calculatedMinutes;
    }

    const showFlightDetails = (flight) => {
        setDetailModalShow(true)
        setFlightDetail(flight);
    }

    const showEditModal = () => {
        setDetailModalShow(false);
        setEditModalShow(true);
    }

    return (
        <table className={'table table-striped'}>
            <thead>
                <tr>
                    <th>Date</th>
                    <th className={'d-none d-sm-table-cell'}>Dep.</th>
                    <th className={'d-none d-sm-table-cell'}>Arr.</th>
                    <th className={'d-none d-sm-none d-md-table-cell'} style={{minWidth: '90px'}}>Aircraft</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>SE</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>ME</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>Multi</th>
                    <th>Total</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>PIC</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>D/L</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>N/L</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>Night</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>IFR</th>
                    <th className={'d-none d-sm-none d-xl-table-cell'}>PIC</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>Copi</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>Dual</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>Instr.</th>
                    <th className={'d-none d-sm-none d-xl-table-cell table-striped-column-l table-striped-left-align'}>Remarks</th>
                </tr>
            </thead>
            {paginatedFlights?.map((flight) => {
                calcFlightTime(flight);
                calcLandings(flight);
                return (
                    <tbody key={`tbody_${flight.flight_id}`}>
                    <tr key={`row_1_${flight.flight_id}`} onClick={() => showFlightDetails(flight)}>
                        <td key={`departure_date_${flight.flight_id}`} rowSpan="2">{flight.departure_date}</td>
                        <td className={'d-none d-sm-table-cell'} key={`departure_${flight.flight_id}`}>{flight.departure}</td>
                        <td className={'d-none d-sm-table-cell'} key={`destination_${flight.flight_id}`}>{flight.destination}</td>
                        <td className={'d-none d-sm-none d-md-table-cell'} key={`icao_type_${flight.flight_id}`}>{flight.icao_type}</td>
                        <td className={'d-none d-sm-none d-lg-table-cell'} key={`se_${flight.se}`} rowSpan="2">{flight.se}</td>
                        <td className={'d-none d-sm-none d-lg-table-cell'} key={`me_${flight.me}`} rowSpan="2">{flight.me}</td>
                        <td className={'d-none d-sm-none d-lg-table-cell'} key={`multi_pilot_${flight.multi_pilot}`} rowSpan="2">{flight.multi_pilot}</td>
                        <td key={`total_time_${flight.total_time}`} rowSpan="2">{flight.total_time}</td>
                        <td className={'d-none d-sm-none d-xl-table-cell table-striped-left-align'} key={`pic_${flight.flight_id}`}
                            rowSpan="2">{flight.pic}</td>
                        <td className={'d-none d-sm-none d-lg-table-cell'} key={`lnd_day_${flight.landings_day}`} rowSpan="2">{flight.landings_day}</td>
                        <td className={'d-none d-sm-none d-lg-table-cell'} key={`lnd_night_${flight.landings_night}`} rowSpan="2">{flight.landings_night}</td>
                        <td className={'d-none d-sm-none d-lg-table-cell'}  key={`night_time_${flight.night_time}`} rowSpan="2">{flight.night_time}</td>
                        <td className={'d-none d-sm-none d-lg-table-cell'}  key={`ifr_time_${flight.ifr_time}`} rowSpan="2">{flight.ifr_time}</td>
                        <td className={'d-none d-sm-none d-lg-table-cell'}  key={`pic_time_${flight.pic_time}`} rowSpan="2">{flight.pic_time}</td>
                        <td className={'d-none d-sm-none d-lg-table-cell'}  key={`copi_time_${flight.copi_time}`} rowSpan="2">{flight.copi_time}</td>
                        <td className={'d-none d-sm-none d-lg-table-cell'}  key={`dual_time_${flight.dual_time}`} rowSpan="2">{flight.dual_time}</td>
                        <td className={'d-none d-sm-none d-lg-table-cell'}  key={`instructor_time_${flight.instructor_time}`} rowSpan="2">{flight.instructor_time}</td>
                        <td className={'d-none d-sm-none d-xl-table-cell table-striped-left-align'} title={flight.remarks}
                            key={`remarks_${flight.remarks_short}`} rowSpan="2">{flight.remarks_short}</td>
                    </tr>
                    <tr key={`row2_${flight.flight_id}`} onClick={() => showFlightDetails(flight)}>
                        <td className={'d-none d-sm-table-cell'} key={`departure_time_${flight.flight_id}`}>{flight.departure_time}</td>
                        <td className={'d-none d-sm-table-cell'} key={`arrival_time_${flight.flight_id}`}>{flight.arrival_time}</td>
                        <td className={'d-none d-sm-none d-md-table-cell'} key={`registration_${flight.flight_id}`}>{flight.registration}</td>
                    </tr>
                    </tbody>
                )
            })}
            <tfoot>
                <tr>
                    <th/>
                    <th className={'d-none d-sm-table-cell'}/>
                    <th className={'d-none d-sm-table-cell'}/>
                    <th className={'d-none d-sm-none d-md-table-cell'}/>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>{getHoursAndMinutes(totalTimes.se)}</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>{getHoursAndMinutes(totalTimes.me)}</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>{getHoursAndMinutes(totalTimes.multi)}</th>
                    <th>{getHoursAndMinutes(totalTimes.total)}</th>
                    <th className={'d-none d-sm-none d-xl-table-cell'}/>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>{totalLandings.day}</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>{totalLandings.night}</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>{getHoursAndMinutes(totalTimes.night)}</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>{getHoursAndMinutes(totalTimes.ifr)}</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>{getHoursAndMinutes(totalTimes.pic)}</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>{getHoursAndMinutes(totalTimes.copi)}</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>{getHoursAndMinutes(totalTimes.dual)}</th>
                    <th className={'d-none d-sm-none d-lg-table-cell'}>{getHoursAndMinutes(totalTimes.instructor)}</th>
                    <th className={'d-none d-sm-none d-xl-table-cell table-striped-left-align'}/>
                </tr>
            </tfoot>
            <LogbookModalFlight
                show={detailModalShow}
                onHide={() => setDetailModalShow(false)}
                flightDetail={flightDetail}
                onEdit={showEditModal}
                onDelete={onFlightDelete}
            />
            <LogbookModalFlightEdit
                show={editModalShow}
                onHide={() => setEditModalShow(false)}
                flightDetail={flightDetail}
                onFlightUpdate={onFlightUpdate}
            />
        </table>
    )
}
