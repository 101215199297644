export function listErrorMsg(errMsg, errRef) {
    let ret = '';
    errMsg.forEach(msg => {
        if (ret !== '') {
            ret = ret + '\n';
        }
        ret = ret + msg;
    });

    if (errRef !== undefined && errRef.current !== undefined && errRef.current !== null) {
        errRef.current.scrollIntoView();
    }

    return ret;
}
