import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Trash } from "react-bootstrap-icons";
import {DeleteAircraftById} from '../api-requests/Requests';

export default function AircraftModal({show, onHide, aircraftDetail, onDelete}) {
    const deleteAircraft = () => {
        DeleteAircraftById(aircraftDetail['aircraft_id'], onHide, onDelete)
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
             <Modal.Header closeButton>
                 <Modal.Title id="contained-modal-title-vcenter">
                     {aircraftDetail['registration']}
                 </Modal.Title>
             </Modal.Header>
             <Modal.Body>
                 <table className={'table-modal'}>
                     <tbody>
                         <tr>
                             <td>Registration</td>
                             <td>{aircraftDetail['registration']}</td>
                         </tr>
                         <tr><td>---</td></tr>
                         <tr>
                             <td>Type</td>
                             <td>{aircraftDetail['icao_type']}</td>
                         </tr>
                     </tbody>
                 </table>
             </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" className={'modalButton'} onClick={deleteAircraft} disabled={aircraftDetail['used'] === 1}>
                    <Trash size={18}/>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
