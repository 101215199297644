import {useState} from 'react';
import AircraftModal from './AircraftModal';

export default function AircraftTable({paginatedAircrafts, onAircraftDelete}) {
    const [aircraftDetail, setAircraftDetail] = useState([]);

    const [detailModalShow, setDetailModalShow] = useState(false);

    const showAircraftDetails = (aircraft) => {
        setDetailModalShow(true)
        setAircraftDetail(aircraft);
    }

    return (
        <table className={'table table-striped'}>
            <thead>
                <tr>
                    <th className={'d-none d-sm-table-cell'}>Registration</th>
                    <th className={'d-none d-sm-table-cell'}>Type</th>
                </tr>
            </thead>
            {paginatedAircrafts?.map((aircraft) => {
                return (
                    <tbody key={`tbody_${aircraft.aircraft_id}`}>
                    <tr key={`row_1_${aircraft.aircraft_id}`} onClick={() => showAircraftDetails(aircraft)}>
                        <td key={`registration_${aircraft.aircraft_id}`} rowSpan="2">{aircraft.registration}</td>
                        <td key={`type_${aircraft.icao_type}`}>{aircraft.icao_type}</td>
                    </tr>
                    </tbody>
                )
            })}
            <AircraftModal
                show={detailModalShow}
                onHide={() => setDetailModalShow(false)}
                aircraftDetail={aircraftDetail}
                onDelete={onAircraftDelete}
            />
        </table>
    )
}
