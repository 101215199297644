export function filterAirportsByValue(airports, value, _callback) {
   let filteredAirports = [];

   airports.forEach(item => {
       if (item.icao_code.includes(value)) {
           filteredAirports.push([
               item.airport_id,
               item.icao_code,
           ]);
       }
   });

   _callback(filteredAirports);
}
