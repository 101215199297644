import axios from '../../api/axios';

const NIGHTTIME_URL = '/nightTime';

export function calcStartDateTime(dateTime, endDateTime, _callback) {
    let offset = dateTime.getTimezoneOffset() * 60,
        departureTimestamp = Math.floor(dateTime.getTime()/ 1000) - offset;

    if (endDateTime !== undefined) {
        _callback(departureTimestamp, calcFlightTime(dateTime, endDateTime));
    } else {
        _callback(departureTimestamp, '0:00');
    }
}

export function calcEndDateTime(dateTime, startDateTime, _callback) {
    let offset = dateTime.getTimezoneOffset() * 60,
        arrivalTimestamp = Math.floor(dateTime.getTime()/ 1000) - offset

    if (startDateTime !== undefined) {
        _callback(arrivalTimestamp, calcFlightTime(startDateTime, dateTime))
    } else {
        _callback(arrivalTimestamp, '0:00')
    }
}

export function calcNightTime(start, end, departure, arrival, _callback) {
    let offset = start.getTimezoneOffset() * 60,
        startTimestamp = Math.floor(start.getTime()/ 1000) - offset,
        endTimestamp = Math.floor(end.getTime()/ 1000) - offset;

    if (departure !== undefined && arrival !== undefined) {
        axios.get(NIGHTTIME_URL+'/'+departure+'/'+startTimestamp+'/'+arrival+'/'+endTimestamp,
            {
                headers        : {
                    'Accept' : 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                },
                withCredentials: true
            }
        ).then(function (response) {
            let hours = Math.floor(response.data / 3600 ),
                minutes = Math.ceil( (response.data % 3600) / 60 );

            if (minutes.toString().length === 1) {
                minutes = '0'+minutes;
            }

            _callback(response.data, hours+':'+minutes);
        }).catch(err => {
            console.log(err)
        })
    } else {
        _callback(0, '0:00');
    }
}

export function getSecondsFromHourAndMinutes(hourAndMinutes, _callback) {
    let [hours, minutes] = hourAndMinutes.split(':'),
        seconds = (Number(hours) * 3600) + (Number(minutes) * 60);

    _callback(seconds);
}

const calcFlightTime = (start, end) => {
    if (end < start) {
        return '0:00';
    }

    var seconds = (Date.parse(end)/1000) - (Date.parse(start)/1000),
        hours = Math.floor(seconds / 3600 ),
        minutes = Math.ceil( (seconds % 3600) / 60 );

    if (minutes.toString().length === 1) {
        minutes = '0'+minutes;
    }

    return (hours+':'+minutes);
}
