import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {CheckLg} from "react-bootstrap-icons";
import {useState, useRef} from 'react';
import {AddNewFir} from '../api-requests/Requests';
import {listErrorMsg} from '../helpers/ErrorMsgHelper';

export default function FirModalAdd({show, onHide, onFirAdd}) {
    const errRef = useRef();

    const [name, setName] = useState();
    const [country, setCountry] = useState();

    const [errMsg, setErrMsg] = useState([]);

    const addFir = () => {
        AddNewFir(
            name,
            country,
            errRef,
            onHide,
            onFirAdd,
            setErrMsg,
        )
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
             <Modal.Header closeButton>
                 <Modal.Title id="contained-modal-title-vcenter">
                     Add new fir
                 </Modal.Title>
             </Modal.Header>
             <Modal.Body>
                 <p ref={errRef} className={errMsg.length === 0 ? "offscreen" : "display-linebreak errmsg"} aria-live="assertive">
                     {listErrorMsg(errMsg, errRef)}
                 </p>
                 <table className={'table-modal'}>
                     <tbody>
                         <tr>
                             <td>
                                 Name
                                 <input
                                     type='text'
                                     className={'filterInput-xl'}
                                     onChange={(e) => setName(e.target.value)}
                                 />
                             </td>
                             <td>
                                 Country <br/>
                                 <input
                                     type='text'
                                     className={'filterInput-xl'}
                                     onChange={(e) => setCountry(e.target.value)}
                                 />
                             </td>
                         </tr>
                     </tbody>
                 </table>
             </Modal.Body>
            <Modal.Footer>
                <Button variant="success" className={'modalButton'} onClick={addFir}>
                    <CheckLg size={18}/>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
