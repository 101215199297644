import axios from '../../api/axios';

const AIRPORT_URL = '/airport';
const AIRCRAFT_URL = '/aircraft';
const FLIGHTS_URL = '/flights';
const FLIGHT_URL = '/flight';
const FIR_URL = '/fir';

export function GetAirports(_callback) {
    axios.get(AIRPORT_URL,
        {
            headers        : {
                'Accept' : 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            withCredentials: true
        }
    ).then(function (response) {
        _callback(response.data);
    }).catch(err => {
        console.log(err)
    })
}

export function GetAircrafts(_callback) {
    axios.get(AIRCRAFT_URL,
        {
            headers        : {
                'Accept' : 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            withCredentials: true
        }
    ).then(function (response) {
        _callback(response.data);
    }).catch(err => {
        console.log(err)
    })
}

export function GetFlights(_callback) {
    axios.get(FLIGHTS_URL,
        {
            headers        : {
                'Accept' : 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            withCredentials: true
        }
    ).then(function (response) {
        _callback(response);
    }).catch(err => {
        console.log(err)
    })
}

export function SaveNewFlight(
    landingsNight,
    landingsDay,
    departureTimestamp,
    arrivalTimestamp,
    departure,
    arrival,
    aircraft,
    engineType,
    multiPilotTime,
    pic,
    nightFlightTimeSeconds,
    ifrFlightTimeSeconds,
    picTime,
    copiTime,
    dualTime,
    instructorTime,
    remarks,
    errRef,
    _setSuccessMsg,
    _setErrMsg,
) {
    try {
        axios.post(FLIGHTS_URL,
            JSON.stringify({
                'departure_time': departureTimestamp,
                'arrival_time': arrivalTimestamp,
                'departure_place': departure,
                'arrival_place': arrival,
                'aircraft': aircraft,
                'engine': engineType.toString().toLowerCase(),
                'multi_pilot': multiPilotTime,
                'pic': pic,
                'landings_day': landingsDay,
                'landings_night': landingsNight,
                'night_time': nightFlightTimeSeconds,
                'ifr_time': ifrFlightTimeSeconds,
                'pic_time': picTime,
                'copi_time': copiTime,
                'dual_time': dualTime,
                'instructor_time': instructorTime,
                'remarks': remarks
            }),
            {
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            }
        ).then (
            _setSuccessMsg(['Flight successfully saved'])
        ).catch((err) => {
            if (err.response?.status === 422) {
                let errorMsg = [];
                Object.values(err.response.data.errors).forEach(error => {
                    errorMsg.push([error[0]]);
                });

                _setErrMsg(errorMsg);
            }
        })
    } catch (err) {
        if (!err?.response) {
            _setErrMsg(['Check flight options']);
        }

        errRef.current.scrollIntoView();
    }
}

export function DeleteFlightById(flightId, _onHide, _onDelete) {
    axios.delete(FLIGHT_URL+'/'+flightId,
        {
            headers        : {
                'Accept' : 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            withCredentials: true
        }
    ).then(function () {
        _onHide();
        _onDelete();
    }).catch(err => {
        console.log(err)
    })
}

export function UpdateFlightById(
    flightId,
    departureTimestamp,
    arrivalTimestamp,
    departure,
    arrival,
    aircraft,
    engineType,
    multiPilotTime,
    pic,
    dayLandings,
    nightLandings,
    nightFlightTimeSeconds,
    ifrFlightTimeSeconds,
    picTime,
    copiTime,
    dualTime,
    instructorTime,
    remarks,
    errRef,
    _onHide,
    _onFlightUpdate,
    _setErrMsg,
) {
    try {
        axios.put(FLIGHT_URL+'/'+flightId,
            JSON.stringify({
                'departure_time': departureTimestamp,
                'arrival_time': arrivalTimestamp,
                'departure_place': departure,
                'arrival_place': arrival,
                'aircraft': aircraft,
                'engine': engineType.toString().toLowerCase(),
                'multi_pilot': multiPilotTime,
                'pic': pic,
                'landings_day': dayLandings,
                'landings_night': nightLandings,
                'night_time': nightFlightTimeSeconds,
                'ifr_time': ifrFlightTimeSeconds,
                'pic_time': picTime,
                'copi_time': copiTime,
                'dual_time': dualTime,
                'instructor_time': instructorTime,
                'remarks': remarks
            }),
            {
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            }
        ).then(response => {
            _onHide();
            _onFlightUpdate();
        }).catch((err) => {
            if (err.response?.status === 422) {
                let errorMsg = [];
                Object.values(err.response.data.errors).forEach(error => {
                    errorMsg.push([error[0]]);
                });

                _setErrMsg(errorMsg);
            } else if (err.response?.status === 500) {
                _setErrMsg(['Server error: Update failed!']);
            }
        })
    } catch (err) {
        if (!err?.response) {
            _setErrMsg(['Check flight options']);
        }

        if (errRef !== undefined && errRef.current !== null) {
            errRef.current.scrollIntoView();
        }
    }
}

export function DeleteAircraftById(aircraftId, _onHide, _onDelete) {
    axios.delete(AIRCRAFT_URL+'/'+aircraftId,
        {
            headers        : {
                'Accept' : 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            withCredentials: true
        }
    ).then(function () {
        _onHide();
        _onDelete();
    }).catch(err => {
        console.log(err)
    })
}

export function AddNewAircraft(
    registration,
    icaoType,
    aircraftType,
    errRef,
    _onHide,
    _onCreate,
    _setErrMsg,
) {
    try {
        axios.post(AIRCRAFT_URL,
            JSON.stringify({
                'registration': registration.toString().toUpperCase(),
                'icao_type': icaoType.toString().toUpperCase(),
                'type': aircraftType,
            }),
            {
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            }
        ).then(response => {
            _onHide();
            _onCreate();
        }).catch((err) => {
            if (err.response?.status === 422) {
                let errorMsg = [];
                Object.values(err.response.data.errors).forEach(error => {
                    errorMsg.push([error[0]]);
                });

                _setErrMsg(errorMsg);
            }
        })
    } catch (err) {
        if (!err?.response) {
            _setErrMsg(['Check aircraft options']);
        }

        errRef.current.scrollIntoView();
    }
}

export function DeleteAirportById(airportId, _onHide, _onDelete) {
    axios.delete(AIRPORT_URL+'/'+airportId,
        {
            headers        : {
                'Accept' : 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            withCredentials: true
        }
    ).then(function () {
        _onHide();
        _onDelete();
    }).catch(err => {
        console.log(err)
    })
}

export function AddNewAirport(
    icaoCode,
    iataCode,
    fir,
    name,
    country,
    coordinates,
    errRef,
    _onHide,
    _onCreate,
    _setErrMsg,
) {
    try {
        axios.post(AIRPORT_URL,
            JSON.stringify({
                'icao_code': icaoCode.toString().toUpperCase(),
                'iata_code': iataCode === undefined
                    ? ''
                    : iataCode.toString().toUpperCase(),
                'name': name,
                'fir': fir,
                'country': country,
                'coordinates': coordinates,
            }),
            {
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                 },
                withCredentials: true
            }).then(response => {
                _onHide();
                _onCreate();
            }).catch((err) => {
                if (err.response?.status === 422) {
                    let errorMsg = [];
                    Object.values(err.response.data.errors).forEach(error => {
                        errorMsg.push([error[0]]);
                    });

                    _setErrMsg(errorMsg);
                }
            })
    } catch (err) {
        console.log('err', err);
        if (!err?.response) {
             _setErrMsg(['Check airport options']);
        }

        errRef.current.scrollIntoView();
    }
}

export function UpdateAirportById(
    airportId,
    icaoCode,
    iataCode,
    name,
    country,
    coordinates,
    fir,
    errRef,
    _onHide,
    _onFlightUpdate,
    _setErrMsg,
) {
    try {
        axios.put(AIRPORT_URL+'/'+airportId,
            JSON.stringify({
                'icao_code': icaoCode,
                'iata_code': iataCode,
                'name': name,
                'country': country,
                'coordinates': coordinates,
                'fir': fir,
            }),
            {
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            }
        ).then(response => {
            _onHide();
            _onFlightUpdate();
        }).catch((err) => {
            if (err.response?.status === 422) {
                let errorMsg = [];
                Object.values(err.response.data.errors).forEach(error => {
                    errorMsg.push([error[0]]);
                });

                _setErrMsg(errorMsg);
            } else if (err.response?.status === 500) {
                _setErrMsg(['Server error: Update failed!']);
            }
        })
    } catch (err) {
        if (!err?.response) {
            _setErrMsg(['Check flight options']);
        }

        if (errRef !== undefined && errRef.current !== null) {
            errRef.current.scrollIntoView();
        }
    }
}

export function GetFirs(_callback) {
    axios.get(FIR_URL,
        {
            headers        : {
                'Accept' : 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            withCredentials: true
        }
    ).then(function (response) {
        _callback(response.data);
    }).catch(err => {
        console.log(err)
    })
}

export function UpdateFirById(
    firId,
    country,
    errRef,
    _onHide,
    _onFirUpdate,
    _setErrMsg,
) {
    try {
        axios.put(FIR_URL+'/'+firId,
            JSON.stringify({
                'country': country,
            }),
            {
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            }
        ).then(response => {
            _onHide();
            _onFirUpdate();
        }).catch((err) => {
            if (err.response?.status === 422) {
                let errorMsg = [];
                Object.values(err.response.data.errors).forEach(error => {
                    errorMsg.push([error[0]]);
                });

                _setErrMsg(errorMsg);
            } else if (err.response?.status === 500) {
                _setErrMsg(['Server error: Update failed!']);
            }
        })
    } catch (err) {
        if (!err?.response) {
            _setErrMsg(['Check FIR options']);
        }

        if (errRef !== undefined && errRef.current !== null) {
            errRef.current.scrollIntoView();
        }
    }
}

export function AddNewFir(
    name,
    country,
    errRef,
    _onHide,
    _onCreate,
    _setErrMsg,
) {
    try {
        axios.post(FIR_URL,
            JSON.stringify({
                'fir': name.toString().toUpperCase(),
                'country': country,
            }),
            {
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            }
        ).then(response => {
            _onHide();
            _onCreate();
        }).catch((err) => {
            if (err.response?.status === 422) {
                let errorMsg = [];
                Object.values(err.response.data.errors).forEach(error => {
                    errorMsg.push([error[0]]);
                });

                _setErrMsg(errorMsg);
            }
        })
    } catch (err) {
        if (!err?.response) {
            _setErrMsg(['Check fir options']);
        }

        errRef.current.scrollIntoView();
    }
}

export function DeleteFirById(firId, _onHide, _onDelete) {
    axios.delete(FIR_URL+'/'+firId,
        {
            headers        : {
                'Accept' : 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            withCredentials: true
        }
    ).then(function () {
        _onHide();
        _onDelete();
    }).catch(err => {
        console.log(err)
    })
}
