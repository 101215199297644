import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { PencilSquare, Trash } from "react-bootstrap-icons";
import {DeleteFlightById} from '../api-requests/Requests';

export default function LogbookModalFlight({show, onHide, flightDetail, onEdit, onDelete}) {

    const deleteFlight = () => {
        DeleteFlightById(flightDetail['flight_id'], onHide, onDelete)
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
             <Modal.Header closeButton>
                 <Modal.Title id="contained-modal-title-vcenter">
                     Flight from {flightDetail['departure']} to {flightDetail['destination']}
                 </Modal.Title>
             </Modal.Header>
             <Modal.Body>
                 <table className={'table-modal'}>
                     <tbody>
                         <tr>
                             <td>Date of flight</td>
                             <td>{flightDetail['departure_date']}</td>
                         </tr>
                         <tr><td>---</td></tr>
                         <tr>
                             <td>Departure</td>
                             <td>{flightDetail['departure']} at {flightDetail['departure_time']}</td>
                         </tr>
                         <tr>
                             <td>Arrival</td>
                             <td>{flightDetail['destination']} at {flightDetail['arrival_time']}</td>
                         </tr>
                         <tr><td>---</td></tr>
                         <tr>
                             <td>Aircraft Type</td>
                             <td>{flightDetail['icao_type']}</td>
                         </tr>
                         <tr>
                             <td>Registration</td>
                             <td>{flightDetail['registration']}</td>
                         </tr>
                         <tr><td>---</td></tr>
                         <tr>
                             <td>PIC</td>
                             <td>{flightDetail['pic']}</td>
                         </tr>
                         <tr>
                             <td>Landings Day</td>
                             <td>{flightDetail['landings_day']}</td>
                         </tr>
                         <tr>
                             <td>Landings Night</td>
                             <td>{flightDetail['landings_night']}</td>
                         </tr>
                         <tr><td>---</td></tr>
                         <tr>
                             <td>Total flight time</td>
                             <td>{flightDetail['total_time']} <br/></td>
                         </tr>
                         <tr>
                             <td>SE</td>
                             <td>{flightDetail['se']}</td>
                         </tr>
                         <tr>
                             <td>ME</td>
                             <td>{flightDetail['me']}</td>
                         </tr>
                         <tr>
                             <td>Pic</td>
                             <td>{flightDetail['pic_time']}</td>
                         </tr>
                         <tr>
                             <td>Dual</td>
                             <td>{flightDetail['dual_time']}</td>
                         </tr>
                         <tr>
                             <td>Night</td>
                             <td>{flightDetail['night_time']}</td>
                         </tr>
                         <tr>
                             <td>IFR</td>
                             <td>{flightDetail['ifr_time']}</td>
                         </tr>
                         <tr>
                             <td>Copi</td>
                             <td>{flightDetail['copi_time']}</td>
                         </tr>
                         <tr>
                             <td>Multipilot</td>
                             <td>{flightDetail['multi_pilot']}</td>
                         </tr>
                         <tr>
                             <td>Instructor</td>
                             <td>{flightDetail['instructor_time']}</td>
                         </tr>
                         <tr><td>---</td></tr>
                         <tr>
                             <td>Remarks</td>
                             <td>{flightDetail['remarks']}</td>
                         </tr>
                     </tbody>
                 </table>
             </Modal.Body>
            <Modal.Footer>
                <Button className={'modalButton'} onClick={onEdit}>
                    <PencilSquare size={18}/>
                </Button>
                <Button variant="danger" className={'modalButton'} onClick={deleteFlight}>
                    <Trash size={18}/>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
