import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {CheckLg} from "react-bootstrap-icons";
import OptionSwitch from '../OptionSwitch';
import {useState, useRef} from 'react';
import {AddNewAircraft} from '../api-requests/Requests';
import {listErrorMsg} from '../helpers/ErrorMsgHelper';

export default function AircraftModalAdd({show, onHide, onAircraftAdd}) {
    const errRef = useRef();

    const [registration, setRegistration] = useState();
    const [icaoType, setIcaoType] = useState();
    const [aircraftType, setAircraftType] = useState();

    const [errMsg, setErrMsg] = useState([]);

    const addAircraft = () => {
        AddNewAircraft(
            registration,
            icaoType,
            aircraftType,
            errRef,
            onHide,
            onAircraftAdd,
            setErrMsg,
        )
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
             <Modal.Header closeButton>
                 <Modal.Title id="contained-modal-title-vcenter">
                     Add new aircraft
                 </Modal.Title>
             </Modal.Header>
             <Modal.Body>
                 <p ref={errRef} className={errMsg.length === 0 ? "offscreen" : "display-linebreak errmsg"} aria-live="assertive">
                     {listErrorMsg(errMsg, errRef)}
                 </p>
                 <table className={'table-modal'}>
                     <tbody>
                         <tr>
                             <td>
                                 <OptionSwitch
                                     group={'AircraftType'}
                                     selection={['plane', 'helicopter']}
                                     setOption={(aircraftType) => setAircraftType(aircraftType.value)}
                                 />
                             </td>
                             <td>
                                 Registration
                                 <input
                                     type='text'
                                     className={'filterInput-s'}
                                     onChange={(e) => setRegistration(e.target.value)}
                                 />
                             </td>
                             <td>
                                 ICAO type
                                 <input
                                     type='text'
                                     className={'filterInput-s'}
                                     onChange={(e) => setIcaoType(e.target.value)}
                                 />
                             </td>
                         </tr>
                     </tbody>
                 </table>
             </Modal.Body>
            <Modal.Footer>
                <Button variant="success" className={'modalButton'} onClick={addAircraft}>
                    <CheckLg size={18}/>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
