import DatePicker from 'react-datepicker';

export default function DateTimeElement({
        dateTime,
        setDateTime,
    }) {
    return (
        <div style={{width: '100%'}} className={'d-flex'}>
            <nav className={'d-flex justify-content-left'} style={{width: '100%'}}>
                <nav className={'filter-section'}>
                    <DatePicker
                        dateFormat="dd.MM.yy HH:mm"
                        showTimeInput
                        timeInputLabel="Time:"
                        selected={dateTime}
                        onChange={(date) => setDateTime(date)}
                        selectsStart
                        className={'filterInput-xl'}
                    />
                </nav>
            </nav>
        </div>
    )
}
