import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import _ from 'lodash';
import {useState} from 'react';

export default function Pagination({data, pageSize, setPageSize, setPaginatedItems}) {
    const [ currentPage, setCurrentPage ] = useState(1);

    const pageCount = data? Math.ceil(data.length/pageSize) :0;
    const pages = _.range(1, pageCount+1);
    const pagination = (pageNo, size) => {
        setCurrentPage(pageNo);
        const startIndex = (pageNo - 1) * size;
        const paginatedFlight = _(data).slice(startIndex).take(size).value();
        setPaginatedItems(paginatedFlight)
    }

    const ResetPageSize = (size) => {
        setPageSize(size);
        pagination(1, size);
    }

    return (
        <div style={{width: '100%'}} className={'d-flex'}>
            <nav className={'d-flex justify-content-left'} style={{width: '50%'}}>
                <ul className={'pagination'}>
                    {
                        pages.map((page) => (
                            <li key={page} className={
                                page === currentPage ? 'page-item active':'page-item'
                            }>
                                <p className='page-link' onClick={() => pagination(page, pageSize)}>{page}</p>
                            </li>
                        ))
                    }
                </ul>
            </nav>
            <nav className={'justify-content-right float-end'} style={{width: '50%'}}>
                <DropdownButton
                    as={ButtonGroup}
                    key='tableDropdown'
                    className='tableDropdown'
                    size="sm"
                    variant="secondary"
                    title={pageSize}
                >
                    <Dropdown.Item eventKey="1" onClick={() => ResetPageSize(5)}>5</Dropdown.Item>
                    <Dropdown.Item eventKey="2" onClick={() => ResetPageSize(15)}>15</Dropdown.Item>
                    <Dropdown.Item eventKey="3" onClick={() => ResetPageSize(30)}>30</Dropdown.Item>
                    <Dropdown.Item eventKey="4" onClick={() => ResetPageSize(50)}>50</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="5" onClick={() => ResetPageSize(data.length)}>All</Dropdown.Item>
                </DropdownButton>
            </nav>
        </div>
    )
}
