import {useState, useEffect} from 'react';
import _ from 'lodash';

export default function AircraftFilter({aircrafts, pageSize, setGridAircrafts, setPaginatedAircrafts}) {
    const [RegistrationFilter, setRegistrationFilter] = useState([]);
    const [TypeFilter, setTypeFilter] = useState();

    useEffect(() => {
        let initialAircrafts = [];
        aircrafts.forEach(item => {
            initialAircrafts.push(item.aircraft_id);
        });

        setRegistrationFilter(initialAircrafts);
        setTypeFilter(initialAircrafts);
    }, [aircrafts])

    const refreshFilteredAircrafts = (regFiltered, typeFiltered) => {
        // eslint-disable-next-line array-callback-return
        let filteredAircrafts = aircrafts.filter((item) => {
            if (regFiltered.includes(item.aircraft_id) && typeFiltered.includes(item.aircraft_id)) {
                return item;
            }
        });

        setGridAircrafts(filteredAircrafts);
        let paginatedAircrafts = _(filteredAircrafts).slice(0).take(pageSize).value();
        setPaginatedAircrafts(paginatedAircrafts);
    }

    const SetRegistrationFilter = (e) => {
        let filteredAircrafts = [];

        aircrafts.forEach(item => {
            if (item.registration.includes(e.target.value.toString().toUpperCase())) {
                filteredAircrafts.push(item.aircraft_id);
            }
        });

        setRegistrationFilter(filteredAircrafts);
        refreshFilteredAircrafts(filteredAircrafts, TypeFilter);
    }

    const SetTypeFilter = (e) => {
        let filteredAircrafts = [];

        aircrafts.forEach(item => {
            if (item.icao_type.includes(e.target.value.toString().toUpperCase())) {
                filteredAircrafts.push(item.aircraft_id);
            }
        });

        setTypeFilter(filteredAircrafts);
        refreshFilteredAircrafts(RegistrationFilter, filteredAircrafts);
    }

    return (
        <div style={{width: '100%'}} className={'d-flex'}>
            <nav className={'d-flex justify-content-left'} style={{width: '100%'}}>
                <nav className={'filter-section'}>
                    <p>Registration</p>
                    <input
                        type='text'
                        onChange={SetRegistrationFilter}
                        className={'filterInput-m'}
                    />
                </nav>
                <nav className={'filter-section'}>
                    <p>Type</p>
                    <input
                        type='text'
                        onChange={SetTypeFilter}
                        className={'filterInput-m'}
                    />
                </nav>
            </nav>
        </div>
    )
}
