import useAuth from '../hooks/useAuth';
import {useEffect, useState, useRef} from 'react';
import _ from 'lodash';
import FirFilter from './fir/FirFilter';
import FirTable from './fir/FirTable';
import Pagination from './Pagination';
import {GetFirs} from './api-requests/Requests';
import Button from 'react-bootstrap/Button';
import {PlusCircle} from 'react-bootstrap-icons';
import FirModalAdd from './fir/FirModalAdd';

const Fir = () => {
    const { auth, loading } = useAuth();

    const [paginatedFirs, setPaginatedFirs] = useState();

    const loadFirs = useRef(true);
    const [addFirModalShow, setAddFirModalShow] = useState(false);

    const [firs, setFirs] = useState([]);
    const [gridFirs, setGridFirs] = useState([]);

    const [pageSize, setPageSize] = useState(10);

    const GetAllFirs = () => {
        // eslint-disable-next-line no-lone-blocks
        {GetFirs(function(response) {
            setFirs(response);
            setGridFirs(response);
            setPaginatedFirs(_(response).slice(0).take(pageSize).value());
        })}
    }

    const addFir = () => {
        setAddFirModalShow(true);
    }

    useEffect(() => {
        if (loadFirs.current) {
            loadFirs.current = false;
            // eslint-disable-next-line no-lone-blocks
            { GetAllFirs() }
        }
        // eslint-disable-next-line
    }, [])

    if (loading) {
        return (
            <section>
                <h1>Loading...</h1>
            </section>
        )
    }

    return (
        <section className={'section-authorized'}>
            <h1>FIR management</h1>
            <br />
            <p>Welcome {auth.givenName}</p>

            <div className={'container mt-3'}>
                <FirFilter
                    firs={firs}
                    pageSize={pageSize}
                    setGridFirs={(grid_firs) => setGridFirs(grid_firs)}
                    setPaginatedFirs={(paginated_firs) => setPaginatedFirs(paginated_firs)}
                />
                <Button variant="success" className={'addButton'} onClick={addFir}>
                    <PlusCircle size={18}/>
                </Button>
                <FirTable
                    paginatedFirs={paginatedFirs}
                    onFirDelete={GetAllFirs}
                    onFirUpdate={GetAllFirs}
                />
                <Pagination
                    data={gridFirs}
                    pageSize={pageSize}
                    setPageSize={(page_size) => setPageSize(page_size)}
                    setPaginatedItems={(paginated_firs) => setPaginatedFirs(paginated_firs)}
                />
                <FirModalAdd
                    show={addFirModalShow}
                    onHide={() => setAddFirModalShow(false)}
                    onFirAdd={GetAllFirs}
                />
            </div>
        </section>
    )
}

export default Fir
