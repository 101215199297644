import {createContext, useRef, useState} from "react";
import {useEffect} from 'react';
import axios from '../api/axios';
import {useNavigate} from 'react-router-dom';

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [loading, setLoading] = useState(true);
    const reloadContext = useRef(true);
    const navigate = useNavigate();
    const USER_URL = '/user';

    const SetContext = () => {
        if (!auth?.email && localStorage.getItem('access_token')) {
            axios.get(USER_URL,
                {
                    headers        : {
                        'Accept' : 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                    },
                    withCredentials: true
                }
            ).then(function (response) {
                if (response?.data?.email) {
                    if (response?.data?.subscription === "none") {
                        setLoading(false)
                        navigate('/noSubscription');
                    } else {
                        const isAdmin = response?.data?.admin;
                        const givenName = response?.data?.given_name;
                        const lastName = response?.data?.last_name;
                        const email = response?.data?.email;
                        setAuth({ isAdmin, givenName, lastName, email });
                        setLoading(false)
                    }
                } else {
                    localStorage.clear();
                    setAuth({});
                    navigate('/login');
                }
            }).catch(function (error) {
                if (error?.response?.status === 401 || !error?.response?.status) {
                    localStorage.clear();
                    setAuth({});
                    navigate('/login');
                }
            })
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (reloadContext.current && !auth.email) {
            reloadContext.current = false;
            // eslint-disable-next-line no-lone-blocks
            { SetContext() }
        }
    // eslint-disable-next-line
    }, [])

    return (
        <AuthContext.Provider value={{ auth, setAuth, loading, setLoading }}>
            {children}
        </AuthContext.Provider>
    )
}

const AuthContext = createContext({});

export default AuthContext;
