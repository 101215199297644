import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import SyncLoader from "react-spinners/ClipLoader";
import { useState } from "react";

const RequireAuth = ({ needAdminRights }) => {
    const { auth, loading } = useAuth();
    const location = useLocation();
    const [color] = useState("#ffffff");

    if (loading) {
        return (
            <section className={'spinner'}>
                <SyncLoader color={color} loading={true} size={150} />
            </section>
        )
    }

    return (
        (needAdminRights && auth.isAdmin) || (!needAdminRights && localStorage.getItem('access_token'))
            ? <Outlet />
            : auth?.email
                ? <Navigate to='/unauthorized' state={{ from: location}} replace />
                : <Navigate to='/login' state={{ from: location}} replace />
    );
}

export default RequireAuth;
