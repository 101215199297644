import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {PencilSquare, Trash} from "react-bootstrap-icons";
import {DeleteAirportById} from '../api-requests/Requests';

export default function AirportModal({show, onHide, airportDetail, onEdit, onDelete}) {
    const deleteAirport = () => {
        DeleteAirportById(airportDetail['airport_id'], onHide, onDelete)
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
             <Modal.Header closeButton>
                 <Modal.Title id="contained-modal-title-vcenter">
                     {airportDetail['icao_code']} - {airportDetail['name']}
                 </Modal.Title>
             </Modal.Header>
             <Modal.Body>
                 <table className={'table-modal'}>
                     <tbody>
                         <tr>
                             <td>ICAO Code</td>
                             <td>{airportDetail['icao_code']}</td>
                         </tr>
                         <tr>
                             <td>IATA Code</td>
                             <td>{airportDetail['iata_code']}</td>
                         </tr>
                         <tr>
                             <td>FIR</td>
                             <td>{airportDetail['fir']}</td>
                         </tr>
                         <tr>
                             <td>Name</td>
                             <td>{airportDetail['name']}</td>
                         </tr>
                         <tr>
                             <td>Country</td>
                             <td>{airportDetail['country']}</td>
                         </tr>
                         <tr>
                             <td>Coordinates</td>
                             <td>{airportDetail['coordinates']}</td>
                         </tr>
                     </tbody>
                 </table>
             </Modal.Body>
            <Modal.Footer>
                <Button className={'modalButton'} onClick={onEdit}>
                    <PencilSquare size={18}/>
                </Button>
                <Button variant="danger" className={'modalButton'} onClick={deleteAirport} disabled={airportDetail['used'] === 1}>
                    <Trash size={18}/>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
