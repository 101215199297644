const Unauthorized = () => {
    return (
        <section className={'section-unauthorized'}>
            <h1>Unauthorized</h1>
            <br />
            <p>You do not have access to the requested page.</p>
        </section>
    )
}

export default Unauthorized
