import Modal from 'react-bootstrap/Modal';
import DateTimeElement from '../DateTimeElement';
import {
    calcStartDateTime,
    calcEndDateTime,
    calcNightTime,
    getSecondsFromHourAndMinutes
} from '../helpers/FlightTimeHelper';
import {useState, useEffect, useRef} from 'react';
import SearchbarDropdown from '../SearchbarDropdown';
import {GetAircrafts, GetAirports, UpdateFlightById} from '../api-requests/Requests';
import {filterAirportsByValue} from '../helpers/AirportHelper';
import {filterAircraftsByValue} from '../helpers/AircraftHelper';
import OptionSwitch from '../OptionSwitch';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {CheckLg} from 'react-bootstrap-icons';
import {listErrorMsg} from '../helpers/ErrorMsgHelper';

export default function LogbookModalFlightEdit({show, onHide, flightDetail, onFlightUpdate}) {
    const errRef = useRef();

    const [departureTimestamp, setDepartureTimestamp] = useState();
    const [arrivalTimestamp, setArrivalTimestamp] = useState();
    const [nightFlightTimeSeconds, setNightFlightTimeSeconds] = useState();
    const [arrival, setArrival] = useState();
    const [aircraft, setAircraft] = useState();
    const [departureAirport, setDepartureAirport] = useState();
    const [arrivalAirport, setArrivalAirport] = useState();
    const [aircraftRegistration, setAircraftRegistration] = useState();
    const [engineType, setEngineType] = useState();
    const [multiPilotTime, setMultiPilotTime] = useState();
    const [pic, setPic] = useState();
    const [dayLandings, setDayLandings] = useState();
    const [nightLandings, setNightLandings] = useState();
    const [remarks, setRemarks] = useState();

    const [filteredDepartureAirports, setFilteredDepartureAirports] = useState([]);
    const [filteredArrivalAirports, setFilteredArrivalAirports] = useState([]);
    const [airports, setAirports] = useState([]);
    const [aircrafts, setAircrafts] = useState([]);
    const [filteredAircrafts, setFilteredAircrafts] = useState([]);
    const [errMsg, setErrMsg] = useState([]);

    const [picTime, setPicTime] = useState(false);
    const [copiTime, setCopiTime] = useState(false);
    const [dualTime, setDualTime] = useState(false);
    const [instructorTime, setInstructorTime] = useState(false);

    const [startDateTime, setStartDateTime] = useState(new Date());
    const [endDateTime, setEndDateTime] = useState(new Date());

    const [flightTime, setFlightTime] = useState('0:00');
    const [nightFlightTime, setNightFlightTime] = useState('0:00');
    const [ifrFlightTime, setIfrFlightTime] = useState('0:00');

    const [departure, setDeparture] = useState(flightDetail['departure']);

    const [ifrFlightTimeSeconds, setIfrFlightTimeSeconds] = useState(0);

    useEffect(() => {
        if (flightDetail['departure_date'] && flightDetail['arrival_date']) {
            setIfrFlightTime(flightDetail['ifr_time']);
            setNightFlightTime(flightDetail['night_time']);
            setEngineType(flightDetail['engine'].toString().toUpperCase())
            setMultiPilotTime(flightDetail['multi_pilot'] !== '0:00')
            setPicTime(flightDetail['pic_time'] !== '0:00')
            setCopiTime(flightDetail['copi_time'] !== '0:00')
            setDualTime(flightDetail['dual_time'] !== '0:00')
            setInstructorTime(flightDetail['instructor_time'] !== '0:00')
            setPic(flightDetail['pic'])
            setDayLandings(flightDetail['landings_day'])
            setNightLandings(flightDetail['landings_night'])
            setRemarks(flightDetail['remarks'] ?? '')

            const [depDay, depMonth, depYear] = flightDetail['departure_date'].split('.');
            const [depHours, depMinutes] = flightDetail['departure_time'].split(':');

            var initialDepartureDate = new Date(depYear, depMonth -1, depDay, depHours, depMinutes);
            setStartDateTime(initialDepartureDate);

            const [arrDay, arrMonth, arrYear] = flightDetail['arrival_date'].split('.');
            const [arrHours, arrMinutes] = flightDetail['arrival_time'].split(':');

            var initialArrivalDate = new Date(arrYear, arrMonth -1, arrDay, arrHours, arrMinutes);
            setEndDateTime(initialArrivalDate);

            calcStartDateTime(initialDepartureDate, initialArrivalDate, function(departureTimestamp, flightTime) {
                setDepartureTimestamp(departureTimestamp);
                setFlightTime(flightTime);
            });

            calcEndDateTime(initialArrivalDate, initialDepartureDate, function(arrivalTimestamp, flightTime) {
               setArrivalTimestamp(arrivalTimestamp)
            });

            getSecondsFromHourAndMinutes(flightDetail['night_time'], function(seconds) {
                setNightFlightTimeSeconds(seconds);
            })

            // eslint-disable-next-line no-lone-blocks
            {GetAirports(function(airports){
                setAirports(airports);
            })}

            // eslint-disable-next-line no-lone-blocks
            {GetAircrafts(function(aircrafts){
                setAircrafts(aircrafts);
            })}
        }
    }, [flightDetail])

    useEffect(() => {
        if (airports.length !== 0) {
            filterAirportsByValue(airports, flightDetail['departure'], function(filteredAirport) {
                setDeparture(filteredAirport[0][0]);
                setDepartureAirport(filteredAirport[0][1])
            })

            filterAirportsByValue(airports, flightDetail['destination'], function(filteredAirport) {
                setArrival(filteredAirport[0][0]);
                setArrivalAirport(filteredAirport[0][1])
            })
        }
        // eslint-disable-next-line
    }, [airports])

    useEffect(() => {
        if (aircrafts.length !== 0) {
            filterAircraftsByValue(aircrafts, flightDetail['registration'], function(filteredAircraft) {
                setAircraft(filteredAircraft[0][0]);
                setAircraftRegistration(filteredAircraft[0][1])
            })
        }
        // eslint-disable-next-line
    }, [aircrafts])

    const setStartDateTimeFunction = (dateTime) => {
        setStartDateTime(dateTime);

        calcStartDateTime(dateTime, endDateTime, function(departureTimestamp, flightTime) {
            setDepartureTimestamp(departureTimestamp);
            setFlightTime(flightTime);
        });

        calcNightTime(dateTime, endDateTime, departure, arrival, function(nightFlightTimeSeconds, nightTime) {
            setNightFlightTimeSeconds(nightFlightTimeSeconds)
            setNightFlightTime(nightTime);
        });
    }

    const setEndDateTimeFunction = (dateTime) => {
        setEndDateTime(dateTime);

        calcEndDateTime(dateTime, startDateTime, function(arrivalTimestamp, flightTime) {
            setArrivalTimestamp(arrivalTimestamp);
            setFlightTime(flightTime);
        });

        calcNightTime(startDateTime, dateTime, departure, arrival, function(nightFlightTimeSeconds, nightTime) {
            setNightFlightTime(nightTime);
            setNightFlightTimeSeconds(nightFlightTimeSeconds)
        });
    }

    const setDepartureFunction = (airport_id) => {
        setDeparture(airport_id);

        calcNightTime(startDateTime, endDateTime, airport_id, arrival, function(nightFlightTimeSeconds, nightFlightTime) {
            setNightFlightTimeSeconds(nightFlightTimeSeconds);
            setNightFlightTime(nightFlightTime);
        });
    }

    const setArrivalFunction = async (airport_id) => {
        setArrival(airport_id);

        calcNightTime(startDateTime, endDateTime, departure, airport_id, function(nightFlightTimeSeconds, nightFlightTime) {
            setNightFlightTimeSeconds(nightFlightTimeSeconds);
            setNightFlightTime(nightFlightTime);
        });
    }

    const setAircraftFunction = (aircraft) => {
        setAircraft(aircraft);
    }

    const setNightFlightTimeFunction = (nightTime) => {
        setNightFlightTime(nightTime);

        getSecondsFromHourAndMinutes(nightTime, function(seconds) {
            setNightFlightTimeSeconds(seconds);
        })
    }

    const setIfrFlightTimeFunction = (ifrTime) => {
        setIfrFlightTime(ifrTime);

        getSecondsFromHourAndMinutes(ifrTime, function(seconds) {
            setIfrFlightTimeSeconds(seconds);
        })
    }

    const updateFlight = () => {
        // eslint-disable-next-line no-lone-blocks
        {UpdateFlightById(
            flightDetail['flight_id'],
            departureTimestamp,
            arrivalTimestamp,
            departure,
            arrival,
            aircraft,
            engineType,
            multiPilotTime,
            pic,
            dayLandings,
            nightLandings,
            nightFlightTimeSeconds,
            ifrFlightTimeSeconds,
            picTime,
            copiTime,
            dualTime,
            instructorTime,
            remarks,
            errRef,
            onHide,
            onFlightUpdate,
            setErrMsg,
        )}
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
             <Modal.Header closeButton>
                 <Modal.Title id="contained-modal-title-vcenter">
                     Edit Flight
                 </Modal.Title>
             </Modal.Header>
             <Modal.Body>
                 <p ref={errRef} className={errMsg.length === 0 ? "offscreen" : "display-linebreak errmsg"} aria-live="assertive">
                     {listErrorMsg(errMsg, errRef)}
                 </p>
                 <table className={'table-modal'}>
                     <tbody>
                         <tr>
                             <td style={{minWidth: '135px'}}>
                                 Dep. date
                                 <DateTimeElement
                                     dateTime={startDateTime}
                                     setDateTime={setStartDateTimeFunction}
                                 />
                             </td>
                             <td style={{minWidth: '135px'}}>
                                 Arr. date
                                 <DateTimeElement
                                     dateTime={endDateTime}
                                     setDateTime={setEndDateTimeFunction}
                                 />
                             </td>
                         </tr>
                         <tr>
                             <td>
                                 Dep. place
                                 <SearchbarDropdown
                                     selection={filteredDepartureAirports}
                                     onInputChange={(e) => filterAirportsByValue(airports, e.target.value, function(filteredAirports){
                                         setFilteredDepartureAirports(filteredAirports)
                                     })}
                                     setPlace={setDepartureFunction}
                                     value={departureAirport}
                                 />
                             </td>
                             <td>
                                 Arr. place
                                 <SearchbarDropdown
                                     selection={filteredArrivalAirports}
                                     onInputChange={(e) => filterAirportsByValue(airports, e.target.value, function(filteredAirports){
                                         setFilteredArrivalAirports(filteredAirports);
                                     })}
                                     setPlace={setArrivalFunction}
                                     value={arrivalAirport}
                                 />
                             </td>
                             <td>
                                 Aircraft
                                 <SearchbarDropdown
                                     selection={filteredAircrafts}
                                     onInputChange={(e) => filterAircraftsByValue(aircrafts, e.target.value, function(filteredAircrafts){
                                         setFilteredAircrafts(filteredAircrafts);
                                     })}
                                     setPlace={setAircraftFunction}
                                     value={aircraftRegistration}
                                 />
                             </td>
                         </tr>
                         <tr>
                             <td>
                                 Flight time
                                 <input
                                     type='text'
                                     className={'filterInput-s'}
                                     value={flightTime}
                                     readOnly={true}
                                 />
                             </td>
                             <td>
                                 Night time
                                 <input
                                     type='text'
                                     className={'filterInput-s'}
                                     value={nightFlightTime}
                                     onChange={(e) => setNightFlightTimeFunction(e.target.value)}
                                 />
                             </td>
                             <td>
                                 IFR <br/>
                                 <input
                                     type='text'
                                     className={'filterInput-s'}
                                     value={ifrFlightTime}
                                     onChange={(e) => setIfrFlightTimeFunction(e.target.value)}
                                 />
                             </td>
                         </tr>
                         <tr>
                             <td>
                                 <OptionSwitch
                                     group={'engineType'}
                                     selection={['SE', 'ME']}
                                     setOption={(engine_type) => setEngineType(engine_type.value)}
                                     value={engineType}
                                 />
                             </td>
                             <td>
                                 <OptionSwitch
                                     group={'multiPilot'}
                                     selection={['Multipilot']}
                                     setOption={(multi_pilot) => setMultiPilotTime(multi_pilot.checked)}
                                     value={multiPilotTime}
                                 />
                             </td>
                             <td>
                                 <OptionSwitch
                                     group={'picTime'}
                                     selection={['PIC-time']}
                                     setOption={(pic_time) => setPicTime(pic_time.checked)}
                                     value={picTime}
                                 />
                             </td>
                         </tr>
                         <tr>
                             <td>
                                 <OptionSwitch
                                     group={'copiTime'}
                                     selection={['Copi-time']}
                                     setOption={(copi_time) => setCopiTime(copi_time.checked)}
                                     value={copiTime}
                                 />
                             </td>
                             <td>
                                 <OptionSwitch
                                     group={'dualTime'}
                                     selection={['Dual-time']}
                                     setOption={(dual_time) => setDualTime(dual_time.checked)}
                                     value={dualTime}
                                 />
                             </td>
                             <td>
                                 <OptionSwitch
                                     group={'instructorTime'}
                                     selection={['Instructor-time']}
                                     setOption={(instructor_time) => setInstructorTime(instructor_time.checked)}
                                     value={instructorTime}
                                 />
                             </td>
                         </tr>
                         <tr>
                             <td>
                                 PIC <br/>
                                 <input
                                     type='text'
                                     className={'filterInput-s'}
                                     onChange={(pic) => setPic(pic.target.value)}
                                     value={pic}
                                 />
                             </td>
                             <td>
                                 Day-landings
                                 <input
                                     type='text'
                                     className={'filterInput-xs'}
                                     placeholder={'Day'}
                                     onChange={(e) => setDayLandings(e.target.value)}
                                     value={dayLandings}
                                 />
                             </td>
                             <td>
                                 Night-landings
                                 <input
                                     type='text'
                                     className={'filterInput-xs'}
                                     placeholder={'Night'}
                                     onChange={(e) => setNightLandings(e.target.value)}
                                     value={nightLandings}
                                 />
                             </td>
                         </tr>
                         <tr>
                             <td colSpan={2}>
                                 <Form.Control
                                     as='textarea'
                                     rows='3'
                                     placeholder={'Remarks'}
                                     value={remarks}
                                     onChange={(e) => setRemarks(e.target.value)}
                                 />
                             </td>
                         </tr>
                     </tbody>
                 </table>
             </Modal.Body>
            <Modal.Footer>
                <Button variant="success" className={'modalButton'} onClick={updateFlight}>
                    <CheckLg size={18}/>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
