export function filterFirsByValue(firs, value, _callback) {
    let filteredFirs = [];

    firs.forEach(item => {
        if (item.fir.includes(value)) {
            filteredFirs.push([
                item.fir_id,
                item.fir
            ]);
        }
    });

    _callback(filteredFirs);
}
