import {useState} from 'react';
import FirModal from './FirModal';
import FirModalEdit from './FirModalEdit';

export default function FirTable({paginatedFirs, onFirDelete, onFirUpdate}) {
    const [firDetail, setFirDetail] = useState([]);

    const [detailModalShow, setDetailModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);

    const showFirDetails = (fir) => {
        setDetailModalShow(true)
        setFirDetail(fir);
    }

    const showEditModal = () => {
        setDetailModalShow(false);
        setEditModalShow(true);
    }

    return (
        <table className={'table table-striped'}>
            <thead>
            <tr>
                <th className={'d-none d-sm-table-cell'}>FIR</th>
                <th className={'d-none d-sm-table-cell'}>Country</th>
            </tr>
            </thead>
            {paginatedFirs?.map((fir) => {
                return (
                    <tbody key={`tbody_${fir.fir_id}`}>
                    <tr key={`row_1_${fir.fir_id}`} onClick={() => showFirDetails(fir)}>
                        <td key={`fir_${fir.fir}`} rowSpan="2">{fir.fir}</td>
                        <td key={`country_${fir.country}`}>{fir.country}</td>
                    </tr>
                    </tbody>
                )
            })}
            <FirModal
                show={detailModalShow}
                onHide={() => setDetailModalShow(false)}
                firDetail={firDetail}
                onEdit={showEditModal}
                onDelete={onFirDelete}
            />
            <FirModalEdit
                show={editModalShow}
                onHide={() => setEditModalShow(false)}
                firDetail={firDetail}
                onFirUpdate={onFirUpdate}
            />
        </table>
    )
}
