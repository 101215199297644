import {useEffect, useRef} from 'react';

export default function SearchbarDropdown({selection, onInputChange, setPlace, value}) {
    const ulRef = useRef();
    const inputRef = useRef();
    useEffect(() => {
        inputRef.current.addEventListener('click', (event) => {
            event.stopPropagation();
            onInputChange(event);
            if (ulRef !== null) {
                ulRef.current.style.display = 'flex';
            }
        });
        document.addEventListener('click', (event) => {
            if (ulRef !== null && ulRef.current !== null) {
                ulRef.current.style.display = 'none';
            }
        });

        if (value !== undefined) {
            inputRef.current.value = value
        }
    // eslint-disable-next-line
    }, []);
    return (
        <div className="search-bar-dropdown filterInput-xl">
            <input
                id="search-bar"
                type="text"
                className="form-control"
                placeholder="Search"
                ref={inputRef}
                onChange={onInputChange}
            />
            <ul id="results" className="list-group" ref={ulRef}>
                {selection.map((option, index) => {
                    return (
                        <button
                            type="button"
                            key={index}
                            onClick={(e) => {
                                inputRef.current.value = option[1];
                                setPlace(option[0], option[1], option[2]);
                            }}
                            className="list-group-item list-group-item-action"
                        >
                            {option[1]}
                        </button>
                    );
                })}
            </ul>
        </div>
    );
};
