import useAuth from '../hooks/useAuth';
import {useEffect, useState, useRef} from 'react';
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import AircraftFilter from './aircraft/AircraftFilter';
import AircraftTable from './aircraft/AircraftTable';
import Pagination from './Pagination';
import {GetAircrafts} from './api-requests/Requests';
import Button from 'react-bootstrap/Button';
import {PlusCircle} from 'react-bootstrap-icons';
import AircraftModalAdd from './aircraft/AircraftModalAdd';

const Aircraft = () => {
    const { auth, loading } = useAuth();

    const [paginatedAircrafts, setPaginatedAircrafts] = useState();

    const loadAircrafts = useRef(true);

    const [addAircraftModalShow, setAddAircraftModalShow] = useState(false);

    const [aircrafts, setAircrafts] = useState([]);
    const [gridAircrafts, setGridAircrafts] = useState([]);

    const [pageSize, setPageSize] = useState(10);

    const GetAllAircrafts = () => {
        // eslint-disable-next-line no-lone-blocks
        {GetAircrafts(function(response) {
            setAircrafts(response);
            setGridAircrafts(response);
            setPaginatedAircrafts(_(response).slice(0).take(pageSize).value());
        })}
    }

    const addAircraft = () => {
        setAddAircraftModalShow(true);
    }

    useEffect(() => {
        if (loadAircrafts.current) {
            loadAircrafts.current = false;
            // eslint-disable-next-line no-lone-blocks
            { GetAllAircrafts() }
        }
        // eslint-disable-next-line
    }, [])

    if (loading) {
        return (
            <section>
                <h1>Loading...</h1>
            </section>
        )
    }
    return (
        <section className={'section-authorized'}>
            <h1>Aircraft management</h1>
            <br />
            <p>Welcome {auth.givenName}</p>

            <div className={'container mt-3'}>
                <AircraftFilter
                    aircrafts={aircrafts}
                    pageSize={pageSize}
                    setGridAircrafts={(grid_aircrafts) => setGridAircrafts(grid_aircrafts)}
                    setPaginatedAircrafts={(paginated_aircrafts) => setPaginatedAircrafts(paginated_aircrafts)}
                />
                <Button variant="success" className={'addButton'} onClick={addAircraft}>
                    <PlusCircle size={18}/>
                </Button>
                <AircraftTable
                    paginatedAircrafts={paginatedAircrafts}
                    onAircraftDelete={GetAllAircrafts}
                />
                <Pagination
                    data={gridAircrafts}
                    pageSize={pageSize}
                    setPageSize={(page_size) => setPageSize(page_size)}
                    setPaginatedItems={(paginated_aircrafts) => setPaginatedAircrafts(paginated_aircrafts)}
                />
                <AircraftModalAdd
                    show={addAircraftModalShow}
                    onHide={() => setAddAircraftModalShow(false)}
                    onAircraftAdd={GetAllAircrafts}
                />
            </div>
        </section>
    )
}

export default Aircraft
