import { useRef, useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import { Check } from "react-bootstrap-icons";
import axios from '../../api/axios';
import {Link} from 'react-router-dom';

const MAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,}$/;
const NAME_REGEX = /^.{2,}/
const REGISTER_URL = "/register";

const Register = () => {
  const givenNameRef = useRef();
  const errRef = useRef();

  const [mail, setMail] = useState("");
  const [validMail, setValidMail] = useState(false);

  const [givenName, setGivenName] = useState("");
  const [validGivenName, setValidGivenName] = useState(false);

  const [lastName, setLastName] = useState("");
  const [validLastName, setValidLastName] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    givenNameRef.current.focus();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const result = MAIL_REGEX.test(mail);
    setValidMail(result);
  }, [mail]);

  useEffect(() => {
    const result = NAME_REGEX.test(givenName);
    setValidGivenName(result);
  }, [givenName]);

  useEffect(() => {
    const result = NAME_REGEX.test(lastName);
    setValidLastName(result);
  }, [lastName]);

  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    setValidPwd(result);
    const match = pwd === matchPwd && pwd !== '';
    setValidMatch(match);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [mail, givenName, lastName, pwd, matchPwd]);

  useEffect(() => {
    document.body.classList.add('public_body');
  // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = MAIL_REGEX.test(mail);
    const v2 = NAME_REGEX.test(givenName);
    const v3 = NAME_REGEX.test(lastName);
    const v4 = PWD_REGEX.test(pwd);
    if (!v1 || !v2 || !v3 || !v4) {
      setErrMsg("Invalid Entry");
      return;
    }

    try {
      await axios.post(
          REGISTER_URL,
          JSON.stringify({ given_name: givenName, last_name: lastName, email: mail, password: pwd, password_confirmation: pwd}),
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            withCredentials: true
          });

          setMail('');
          setGivenName('');
          setLastName('');
          setPwd('');
          setMail('');
          setSuccess(true);
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response')
      } else {
        setErrMsg('Registration failed')
      }
      errRef.current.focus();
      document.body.classList.remove('public_body');
    }
  };

  return (
    <>
      <main className={'app-unauthorized'} >
        {success ? (
            <section className={'section-without-login'}>
            <h1>Success!</h1>
            <p>
              <Link to="/login">Sign In</Link>
            </p>
          </section>
        ) : (
          <section className={'section-without-login'}>
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <h1>Register</h1>
            <form onSubmit={handleSubmit}>
              <input
                  type="text"
                  id="givenName"
                  placeholder="First Name"
                  autoComplete="off"
                  ref={givenNameRef}
                  onChange={(e) => setGivenName(e.target.value)}
                  value={givenName}
                  required
                  aria-invalid={validGivenName ? "false" : "true"}
              />
              <Check
                  className={validGivenName ? "valid" : "hide"}
                  size={25}
              />

              <input
                  type="text"
                  id="lastName"
                  placeholder="Last Name"
                  autoComplete="off"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  required
                  aria-invalid={validLastName ? "false" : "true"}
              />
              <Check
                  className={validLastName ? "valid" : "hide"}
                  size={25}
              />

              <input
                  type="text"
                  id="mail"
                  placeholder="E-Mail"
                  autoComplete="off"
                  onChange={(e) => setMail(e.target.value)}
                  value={mail}
                  required
                  aria-invalid={validMail ? "false" : "true"}
                  aria-describedby="uidnote"
              />
              <Check
                  className={validMail ? "valid" : "hide"}
                  size={25}
              />

              <input
                type="password"
                id="password"
                placeholder="Password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
                aria-invalid={validPwd ? "false" : "true"}
                aria-describedby="pwdnote"
              />
              <Check
                  className={validPwd ? "valid" : "hide"}
                  size={25}
              />

              <input
                type="password"
                id="confirm_pwd"
                placeholder="Confirm Password"
                onChange={(e) => setMatchPwd(e.target.value)}
                value={matchPwd}
                required
                aria-invalid={validMatch ? "false" : "true"}
                aria-describedby="confirmnote"
              />
              <Check
                  className={validMatch ? "valid" : "hide"}
                  size={25}
              />
              <p
                  id="confirmnote"
                  className={ "instructions"}
              >
                Min. 8 characters and must include uppercase and
                <br />
                lowercase letters, a number and a special character.
                <br />
                Allowed special characters:{" "}
                <span aria-label="exclamation mark">!</span>{" "}
                <span aria-label="at symbol">@</span>{" "}
                <span aria-label="hashtag">#</span>{" "}
                <span aria-label="dollar sign">$</span>{" "}
                <span aria-label="percent">%</span>
              </p>

              <Button type="submit" variant="primary" disabled={!validMail || !validPwd || !validMatch ? true : false}>
                Sign Up
              </Button>
            </form>
            <p>
              <span className="line">
                Already registered?
                <br />
                <Link to="/login">Sign In</Link>
              </span>
            </p>
          </section>
        )}
      </main>
    </>
  );
};

export default Register;
