import useAuth from '../hooks/useAuth';
import {useEffect, useState, useRef} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import DateTimeElement from './DateTimeElement';
import SearchbarDropdown from './SearchbarDropdown';
import OptionSwitch from './OptionSwitch';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {
    calcStartDateTime,
    calcEndDateTime,
    calcNightTime,
    getSecondsFromHourAndMinutes
} from './helpers/FlightTimeHelper';
import {GetAirports, GetAircrafts, SaveNewFlight} from './api-requests/Requests';
import {filterAirportsByValue} from './helpers/AirportHelper';
import {filterAircraftsByValue} from './helpers/AircraftHelper';
import {listErrorMsg} from './helpers/ErrorMsgHelper';

const Logbook = () => {
    const { loading } = useAuth();

    const errRef = useRef();
    const successRef = useRef();
    const loadAirports = useRef(true);

    const [departure, setDeparture] = useState();
    const [arrival, setArrival] = useState();
    // const [depFir, setDepFir] = useState();
    // const [arrFir, setArrFir] = useState();
    const [aircraft, setAircraft] = useState();
    const [engineType, setEngineType] = useState();
    const [pic, setPic] = useState();
    const [dayLandings, setDayLandings] = useState();
    const [nightLandings, setNightLandings] = useState();
    const [departureTimestamp, setDepartureTimestamp] = useState();
    const [arrivalTimestamp, setArrivalTimestamp] = useState();
    const [remarks, setRemarks] = useState();
    const [nightFlightTimeSeconds, setNightFlightTimeSeconds] = useState();

    const [airports, setAirports] = useState([]);
    const [aircrafts, setAircrafts] = useState([]);
    const [filteredDepartureAirports, setfilteredDepartureAirports] = useState([]);
    const [filteredArrivalAirports, setfilteredArrivalAirports] = useState([]);
    const [filteredAircrafts, setfilteredAircrafts] = useState([]);
    const [successMsg, setSuccessMsg] = useState([]);
    const [errMsg, setErrMsg] = useState([]);

    const [multiPilotTime, setMultiPilotTime] = useState(false);
    const [picTime, setPicTime] = useState(false);
    const [copiTime, setCopiTime] = useState(false);
    const [dualTime, setDualTime] = useState(false);
    const [instructorTime, setInstructorTime] = useState(false);

    const [startDateTime, setStartDateTime] = useState(new Date());
    const [endDateTime, setEndDateTime] = useState(new Date());

    const [flightTime, setFlightTime] = useState('0:00');
    const [nightFlightTime, setNightFlightTime] = useState('0:00');
    const [ifrFlightTime, setIfrFlightTime] = useState('0:00');

    const [ifrFlightTimeSeconds, setIfrFlightTimeSeconds] = useState(0);

    useEffect(() => {
        if (loadAirports.current) {
            loadAirports.current = false;
            // eslint-disable-next-line no-lone-blocks
            {
                GetAirports(function(airports){
                    setAirports(airports);
                })
            }
            // eslint-disable-next-line no-lone-blocks
            {
                GetAircrafts(function(aircrafts) {
                    setAircrafts(aircrafts);
                })
            }
        }
    // eslint-disable-next-line
    }, [])

    const setStartDateTimeFunction = (dateTime) => {
        setStartDateTime(dateTime);

        calcStartDateTime(dateTime, endDateTime, function(departureTimestamp, flightTime) {
            setDepartureTimestamp(departureTimestamp);
            setFlightTime(flightTime);
        });

        calcNightTime(dateTime, endDateTime, departure, arrival, function(nightFlightTimeSeconds, nightTime) {
            setNightFlightTime(nightTime);
            setNightFlightTimeSeconds(nightFlightTimeSeconds)
        });
    }

    const setEndDateTimeFunction = (dateTime) => {
        setEndDateTime(dateTime);

        calcEndDateTime(dateTime, startDateTime, function(arrivalTimestamp, flightTime) {
            setArrivalTimestamp(arrivalTimestamp);
            setFlightTime(flightTime);
        });

        calcNightTime(startDateTime, dateTime, departure, arrival, function(nightFlightTimeSeconds, nightTime) {
            setNightFlightTime(nightTime);
            setNightFlightTimeSeconds(nightFlightTimeSeconds)
        });
    }

    const setDepartureFunction = (airport_id) => {
        setDeparture(airport_id);

        calcNightTime(startDateTime, endDateTime, airport_id, arrival, function(nightFlightTimeSeconds, nightFlightTime) {
            setNightFlightTimeSeconds(nightFlightTimeSeconds);
            setNightFlightTime(nightFlightTime);
        });
    }

    const setArrivalFunction = async (airport_id) => {
        setArrival(airport_id);
        console.log('arrival', airport_id);

        calcNightTime(startDateTime, endDateTime, departure, airport_id, function(nightFlightTimeSeconds, nightFlightTime) {
            setNightFlightTimeSeconds(nightFlightTimeSeconds);
            setNightFlightTime(nightFlightTime);
        });
    }

    const setAircraftFunction = (aircraft) => {
        setAircraft(aircraft);
    }

    const setNightFlightTimeFunction = (nightTime) => {
        setNightFlightTime(nightTime);

        getSecondsFromHourAndMinutes(nightTime, function(seconds) {
            setNightFlightTimeSeconds(seconds);
        })
    }

    const setIfrFlightTimeFunction = (ifrTime) => {
        setIfrFlightTime(ifrTime);

        getSecondsFromHourAndMinutes(ifrTime, function(seconds) {
            setIfrFlightTimeSeconds(seconds);
        })
    }

    const saveFlight = () => {
        var landingsNight = nightLandings === undefined
                ? 0
                : nightLandings,
            landingsDay = dayLandings === undefined
                ? 0
                : dayLandings;

        SaveNewFlight(
            landingsNight,
            landingsDay,
            departureTimestamp,
            arrivalTimestamp,
            departure,
            arrival,
            aircraft,
            engineType,
            multiPilotTime,
            pic,
            nightFlightTimeSeconds,
            ifrFlightTimeSeconds,
            picTime,
            copiTime,
            dualTime,
            instructorTime,
            remarks,
            errRef,
            function(msg) {
                setSuccessMsg(msg)
            },
            function(msg) {
                setErrMsg(msg)
            }
        )
    }

    if (loading) {
        return (
            <section>
                <h1>Loading...</h1>
            </section>
        )
    }

    return (
        <section className={'section-authorized'}>
            <h1>Add new flight</h1>

            <div className={'container mt-3'}>
                <p ref={errRef} className={errMsg.length === 0 ? "offscreen" : "display-linebreak errmsg"} aria-live="assertive">
                    {listErrorMsg(errMsg, errRef)}
                </p>
                <p ref={successRef} className={successMsg.length === 0 ? "offscreen" : "display-linebreak successmsg"} aria-live="assertive">
                    {listErrorMsg(successMsg, successRef)}
                </p>
                <table className={'plusTable'}>
                    <tbody>
                    <tr>
                        <td style={{minWidth: '135px'}}>
                            <p>Dep. date</p>
                            <DateTimeElement
                                dateTime={startDateTime}
                                setDateTime={setStartDateTimeFunction}
                            />
                        </td>
                        <td style={{minWidth: '135px'}}>
                            <p>Arr. Date</p>
                            <DateTimeElement
                                dateTime={endDateTime}
                                setDateTime={setEndDateTimeFunction}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Dep. place</p>
                            <SearchbarDropdown
                                selection={filteredDepartureAirports}
                                onInputChange={(e) => filterAirportsByValue(airports, e.target.value, function(filteredAirports){
                                    setfilteredDepartureAirports(filteredAirports)
                                })}
                                setPlace={setDepartureFunction}
                            />
                        </td>
                        <td>
                            <p>Arr. place</p>
                            <SearchbarDropdown
                                selection={filteredArrivalAirports}
                                onInputChange={(e) => filterAirportsByValue(airports, e.target.value, function(filteredAirports){
                                    setfilteredArrivalAirports(filteredAirports);
                                })}
                                setPlace={setArrivalFunction}
                            />
                        </td>
                        <td>
                            <p>Aircraft</p>
                            <SearchbarDropdown
                                selection={filteredAircrafts}
                                onInputChange={(e) => filterAircraftsByValue(aircrafts, e.target.value, function(filteredAircrafts){
                                    setfilteredAircrafts(filteredAircrafts);
                                })}
                                setPlace={setAircraftFunction}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Flight time</p>
                            <input
                                type='text'
                                className={'filterInput-s'}
                                value={flightTime}
                                readOnly={true}
                            />
                        </td>
                        <td>
                            <p>Night time</p>
                            <input
                                type='text'
                                className={'filterInput-s'}
                                value={nightFlightTime}
                                onChange={(e) => setNightFlightTimeFunction(e.target.value)}
                            />
                        </td>
                        <td>
                            <p>IFR time</p>
                            <input
                                type='text'
                                className={'filterInput-s'}
                                value={ifrFlightTime}
                                onChange={(e) => setIfrFlightTimeFunction(e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <OptionSwitch
                                group={'engineType'}
                                selection={['SE', 'ME']}
                                setOption={(engine_type) => setEngineType(engine_type.value)}
                            />
                        </td>
                        <td>
                            <OptionSwitch
                                group={'multiPilot'}
                                selection={['Multipilot']}
                                setOption={(multi_pilot) => setMultiPilotTime(multi_pilot.checked)}
                            />
                        </td>
                        <td>
                            <OptionSwitch
                                group={'picTime'}
                                selection={['PIC-time']}
                                setOption={(pic_time) => setPicTime(pic_time.checked)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <OptionSwitch
                                group={'copiTime'}
                                selection={['Copi-time']}
                                setOption={(copi_time) => setCopiTime(copi_time.checked)}
                            />
                        </td>
                        <td>
                            <OptionSwitch
                                group={'dualTime'}
                                selection={['Dual-time']}
                                setOption={(dual_time) => setDualTime(dual_time.checked)}
                            />
                        </td>
                        <td>
                            <OptionSwitch
                                group={'instructorTime'}
                                selection={['Instructor-time']}
                                setOption={(instructor_time) => setInstructorTime(instructor_time.checked)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>PIC</p>
                            <input
                                type='text'
                                className={'filterInput-s'}
                                onChange={(pic) => setPic(pic.target.value)}
                            />
                        </td>
                        <td>
                            <p>Landings</p>
                            <input
                                type='text'
                                className={'filterInput-xs'}
                                placeholder={'Day'}
                                onChange={(e) => setDayLandings(e.target.value)}
                            />&nbsp;
                            <input
                                type='text'
                                className={'filterInput-xs'}
                                placeholder={'Night'}
                                onChange={(e) => setNightLandings(e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <Form.Control
                                as='textarea'
                                rows='3'
                                name='address'
                                placeholder={'Remarks'}
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Button variant="primary" onClick={saveFlight}>
                                Save
                            </Button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}

export default Logbook
