import {useState, useEffect} from 'react';
import _ from 'lodash';

export default function AirportFilter({airports, pageSize, setGridAirports, setPaginatedAirports}) {
    const [IcaoCodeFilter, setIcaoCodeFilter] = useState([]);
    const [IataCodeFilter, setIataCodeFilter] = useState();
    const [NameFilter, setNameFilter] = useState();
    const [CountryFilter, setCountryFilter] = useState();

    useEffect(() => {
        let initialAirports = [];
        airports.forEach(item => {
            initialAirports.push(item.airport_id);
        });

        setIcaoCodeFilter(initialAirports);
        setIataCodeFilter(initialAirports);
        setNameFilter(initialAirports);
        setCountryFilter(initialAirports);
    }, [airports])

    const refreshFilteredAirports = (icaoCodeFiltered, iataCodeFiltered, nameFiltered, countryFiltered) => {
        // eslint-disable-next-line array-callback-return
        let filteredAirports = airports.filter((item) => {
            if (icaoCodeFiltered.includes(item.airport_id) && iataCodeFiltered.includes(item.airport_id)
                && nameFiltered.includes(item.airport_id) && countryFiltered.includes(item.airport_id)) {
                return item;
            }
        });

        setGridAirports(filteredAirports);
        let paginatedAirports = _(filteredAirports).slice(0).take(pageSize).value();
        setPaginatedAirports(paginatedAirports);
    }

    const SetIcaoCodeFilter = (e) => {
        let filteredAirports = [];

        airports.forEach(item => {
            if (item.icao_code.includes(e.target.value.toString().toUpperCase())) {
                filteredAirports.push(item.airport_id);
            }
        });

        setIcaoCodeFilter(filteredAirports);
        refreshFilteredAirports(filteredAirports, IataCodeFilter, NameFilter, CountryFilter);
    }

     const SetIataCodeFilter = (e) => {
         let filteredAirports = [];

         airports.forEach(item => {
             if (item.iata_code.includes(e.target.value.toString().toUpperCase())) {
                 filteredAirports.push(item.airport_id);
             }
         });

         setIataCodeFilter(filteredAirports);
         refreshFilteredAirports(IcaoCodeFilter, filteredAirports, NameFilter, CountryFilter);
     }

    const SetNameFilter = (e) => {
        let filteredAirports = [];

        airports.forEach(item => {
            if (item.name.includes(e.target.value)) {
                filteredAirports.push(item.airport_id);
            }
        });

        setNameFilter(filteredAirports);
        refreshFilteredAirports(IcaoCodeFilter, IataCodeFilter, filteredAirports, CountryFilter);
    }

    const SetCountryFilter = (e) => {
        let filteredAirports = [];

        airports.forEach(item => {
            if (item.country.includes(e.target.value)) {
                filteredAirports.push(item.airport_id);
            }
        });

        setCountryFilter(filteredAirports);
        refreshFilteredAirports(IcaoCodeFilter, IataCodeFilter, NameFilter, filteredAirports);
    }

    return (
        <div style={{width: '100%'}} className={'d-flex'}>
             <nav className={'d-flex justify-content-left'} style={{width: '100%'}}>
                 <nav className={'filter-section'}>
                     <p>ICAO Code</p>
                     <input
                         type='text'
                         onChange={SetIcaoCodeFilter}
                         className={'filterInput-m'}
                    />
                 </nav>
                 <nav className={'filter-section'}>
                     <p>IATA Code</p>
                     <input
                         type='text'
                         onChange={SetIataCodeFilter}
                         className={'filterInput-m'}
                     />
                 </nav>
                 <nav className={'filter-section'}>
                     <p>Name</p>
                     <input
                         type='text'
                         onChange={SetNameFilter}
                         className={'filterInput-m'}
                     />
                 </nav>
                 <nav className={'filter-section'}>
                     <p>Country</p>
                     <input
                         type='text'
                         onChange={SetCountryFilter}
                         className={'filterInput-m'}
                     />
                 </nav>
             </nav>
         </div>
     )
}
