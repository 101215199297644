import useAuth from '../hooks/useAuth';
import {useEffect, useState, useRef} from 'react';
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import LogbookFilter from './logbook/LogbookFilter';
import LogbookTable from './logbook/LogbookTable';
import Pagination from './Pagination';
import DateFilter from './DateFilter';
import {GetFlights} from './api-requests/Requests';

const Logbook = () => {
    const { auth, loading } = useAuth();

    const [paginatedFlights, setPaginatedFlights] = useState();

    const loadFlights = useRef(true);

    const [flights, setFlights] = useState([]);
    const [gridFlights, setGridFlights] = useState([]);
    const [allFlights, setAllFlights] = useState([]);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [pageSize, setPageSize] = useState(5);

    const GetAllFlights = () => {
        // eslint-disable-next-line no-lone-blocks
        {GetFlights(function(response) {
            setFlights(response.data);
            setAllFlights(response.data);
            setGridFlights(response.data);
            setPaginatedFlights(_(response.data).slice(0).take(pageSize).value());

            if (response.data[0] !== undefined) {
                let [day, month, year] = response.data.slice(-1)[0].departure_date.split('.');

                setStartDate(new Date(year, month -1, day));
                setEndDate(new Date());
            }
        })}
    }

    useEffect(() => {
        if (loadFlights.current) {
            loadFlights.current = false;
            // eslint-disable-next-line no-lone-blocks
            { GetAllFlights() }
        }
    // eslint-disable-next-line
    }, [])

    if (loading) {
        return (
            <section>
                <h1>Loading...</h1>
            </section>
        )
    }
    return (
        <section className={'section-authorized'}>
            <h1>Logbook</h1>
            <br />
            <p>Welcome {auth.givenName}</p>

            <div className={'container mt-3'}>
                <DateFilter
                    allData={allFlights}
                    startDate={startDate}
                    endDate={endDate}
                    pageSize={pageSize}
                    setData={(flights) => setFlights(flights)}
                    setGridData={(grid_flights) => setGridFlights(grid_flights)}
                    setPaginatedData={(paginated_data) => setPaginatedFlights(paginated_data)}
                    setStartDate={(start_date) => setStartDate(start_date)}
                    setEndDate={(end_date) => setEndDate(end_date)}
                />
                <LogbookFilter
                    flights={flights}
                    pageSize={pageSize}
                    setGridFlights={(grid_flights) => setGridFlights(grid_flights)}
                    setPaginatedFlights={(paginated_flights) => setPaginatedFlights(paginated_flights)}
                />
                <LogbookTable
                    paginatedFlights={paginatedFlights}
                    onFlightDelete={GetAllFlights}
                    onFlightUpdate={GetAllFlights}
                />
                <Pagination
                    data={gridFlights}
                    pageSize={pageSize}
                    setPageSize={(page_size) => setPageSize(page_size)}
                    setPaginatedItems={(paginated_flights) => setPaginatedFlights(paginated_flights)}
                />
            </div>
        </section>
    )
}

export default Logbook
