import Register from './components/login/Register';
import Login from './components/login/Login';
import Layout from './components/Layout';
import Logbook from './components/Logbook';
import Unauthorized from './components/Unauthorized';
import Missing from './components/Missing';
import RequireAuth from './components/RequireAuth';
import Aircraft from './components/Aircraft';
import Airport from './components/Airport';
import LogbookEntry from './components/LogbookEntry';
import NoSubscription from './components/NoSubscription';
import Fir from './components/Fir';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        <Route path='/' element={<Layout />} >
            {/* protected routes */}
            <Route element={<RequireAuth needAdminRights={true}/>}>
                <Route path="aircraft" element={<Aircraft />} />
                <Route path="airport" element={<Airport />} />
                <Route path="fir" element={<Fir />} />
            </Route>

            <Route element={<RequireAuth needAdminRights={false} />}>
                <Route path='/' element={<Logbook/>}/>
                <Route path="logbookEntry" element={<LogbookEntry />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/noSubscription" element={<NoSubscription />} />
            </Route>

            {/* catch all */}
            <Route path="*" element={<Missing />} />
        </Route>
    </Routes>
  );
}

export default App;
