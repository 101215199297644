import {useState, useEffect} from 'react';
import _ from 'lodash';

export default function LogbookFilter({flights, pageSize, setGridFlights, setPaginatedFlights}) {
    const [DepFilter, setDepFilter] = useState([]);
    const [ArrFilter, setArrFilter] = useState();
    const [AcFilter, setAcFilter] = useState([]);
    const [PicFilter, setPicFilter] = useState([]);
    const [RemFilter, setRemFilter] = useState([]);

    useEffect(() => {
        let initialFlights = [];
        flights.forEach(item => {
            initialFlights.push(item.flight_id);
        });

        setDepFilter(initialFlights);
        setArrFilter(initialFlights);
        setAcFilter(initialFlights);
        setPicFilter(initialFlights);
        setRemFilter(initialFlights);
    }, [flights])

    const refreshFilteredFlights = (depFiltered, arrFiltered, acFiltered, picFiltered, remFiltered) => {
        // eslint-disable-next-line array-callback-return
        let filteredFlights = flights.filter((item) => {
            if (depFiltered.includes(item.flight_id) && arrFiltered.includes(item.flight_id)
                && acFiltered.includes(item.flight_id) && picFiltered.includes(item.flight_id)
                && remFiltered.includes(item.flight_id)) {
                return item;
            }
        });

        setGridFlights(filteredFlights);
        let paginatedFligts = _(filteredFlights).slice(0).take(pageSize).value();
        setPaginatedFlights(paginatedFligts);
    }

    const SetDepartureFilter = (e) => {
        let filteredFlights = [];

        flights.forEach(item => {
            if (item.departure.includes(e.target.value.toString().toUpperCase())) {
                filteredFlights.push(item.flight_id);
            }
        });

        setDepFilter(filteredFlights);
        refreshFilteredFlights(filteredFlights, ArrFilter, AcFilter, PicFilter, RemFilter);
    }

    const SetArrivalFilter = (e) => {
        let filteredFlights = [];

        flights.forEach(item => {
            if (item.destination.includes(e.target.value.toString().toUpperCase())) {
                filteredFlights.push(item.flight_id);
            }
        });

        setArrFilter(filteredFlights);
        refreshFilteredFlights(DepFilter, filteredFlights, AcFilter, PicFilter, RemFilter);
    }

    const SetAircraftFilter = (e) => {
        let filteredFlights = [];

        flights.forEach(item => {
            if (item.registration.includes(e.target.value.toString().toUpperCase())
                || item.icao_type.includes(e.target.value.toString().toUpperCase())) {
                filteredFlights.push(item.flight_id);
            }
        });

        setAcFilter(filteredFlights);
        refreshFilteredFlights(DepFilter, ArrFilter, filteredFlights, PicFilter, RemFilter);
    }

    const SetPicFilter = (e) => {
        let filteredFlights = [];

        flights.forEach(item => {
            if (item.pic.includes(e.target.value)) {
                filteredFlights.push(item.flight_id);
            }
        });

        setPicFilter(filteredFlights);
        refreshFilteredFlights(DepFilter, ArrFilter, AcFilter, filteredFlights, RemFilter);
    }

    const SetRemFilter = (e) => {
        let filteredFlights = [];

        flights.forEach(item => {
            if (item.remarks.includes(e.target.value)) {
                filteredFlights.push(item.flight_id);
            }
        });

        setRemFilter(filteredFlights);
        refreshFilteredFlights(DepFilter, ArrFilter, AcFilter, PicFilter, filteredFlights);
    }

    return (
        <div style={{width: '100%'}} className={'d-flex'}>
            <nav className={'d-flex justify-content-left'} style={{width: '100%'}}>
                <nav className={'filter-section'}>
                    <p>Dep.</p>
                    <input
                        type='text'
                        onChange={SetDepartureFilter}
                        className={'filterInput-s'}
                    />
                </nav>
                <nav className={'filter-section'}>
                    <p>Arr.</p>
                    <input
                        type='text'
                        onChange={SetArrivalFilter}
                        className={'filterInput-s'}
                    />
                </nav>
                <nav className={'filter-section'}>
                    <p>Aircraft</p>
                    <input
                        type='text'
                        onChange={SetAircraftFilter}
                        className={'filterInput-s'}
                    />
                </nav>
                <nav className={'filter-section'}>
                    <p>PIC</p>
                    <input
                        type='text'
                        onChange={SetPicFilter}
                        className={'filterInput-l'}
                    />
                </nav>
                <nav className={'filter-section'}>
                    <p>Remarks</p>
                    <input
                        type='text'
                        onChange={SetRemFilter}
                        className={'filterInput-l'}
                    />
                </nav>
            </nav>
        </div>
    )
}
