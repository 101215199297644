import { useRef, useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import axios from '../../api/axios';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate, useLocation} from 'react-router-dom';

const LOGIN_URL = '/login';

const Login = () => {
    const { setAuth, setLoading } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from.pathname || '/';

    const mailRef = useRef();
    const errRef = useRef();

    const [mail, setMail] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        mailRef.current.focus();
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [mail, pwd])

    useEffect(() => {
        document.body.classList.add('public_body');
    // eslint-disable-next-line
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ email: mail, password: pwd }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    withCredentials: true
                }
            );
            if (response?.data?.user?.subscription_id === 1) {
                const accessToken = response?.data?.access_token;
                localStorage.setItem('access_token', accessToken)
                navigate('/noSubscription');
            } else {
                const accessToken = response?.data?.access_token;
                const isAdmin = response?.data?.user?.admin;
                const givenName = response?.data?.user?.given_name;
                const lastName = response?.data?.user?.last_name;
                const email = response?.data?.user?.email;
                setAuth({ isAdmin, givenName, lastName, email });
                setMail('');
                setPwd('');
                localStorage.setItem('access_token', accessToken)
                document.body.classList.remove('public_body');
                navigate(from, { replace: true});
            }
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 422) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
        setLoading(false);
    }

    return (
        <main className={'app-unauthorized'} >
            <section className={'section-without-login'}>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <h1>Sign In</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        id="mail"
                        placeholder="E-Mail"
                        ref={mailRef}
                        autoComplete="off"
                        onChange={(e) => setMail(e.target.value)}
                        value={mail}
                        required
                    />

                    <input
                        type="password"
                        id="password"
                        placeholder="Password"
                        onChange={(e) => setPwd(e.target.value)}
                        value={pwd}
                        required
                    />
                    <Button type="submit" variant="primary">
                        Sign In
                    </Button>
                </form>
                <p>
                    <span className="line">
                        Need an Account?<br />
                        <Link to="/register">Sign Up</Link>
                    </span>
                </p>
            </section>
        </main>
    )
}

export default Login
