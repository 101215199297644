import {useState} from 'react';
import AirportModal from './AirportModal';
import AirportModalEdit from './AirportModalEdit';

export default function AirportTable({paginatedAirports, onAirportDelete, onAirportUpdate}) {
    const [airportDetail, setAirportDetail] = useState([]);

    const [detailModalShow, setDetailModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);

    const showAirportDetails = (airport) => {
        setDetailModalShow(true)
        setAirportDetail(airport);
    }

    const showEditModal = () => {
        setDetailModalShow(false);
        setEditModalShow(true);
    }

    return (
        <table className={'table table-striped'}>
            <thead>
                <tr>
                    <th className={'d-none d-sm-table-cell'}>ICAO Code</th>
                    <th className={'d-none d-sm-table-cell'}>IATA Code</th>
                    <th className={'d-none d-sm-table-cell'}>FIR</th>
                    <th className={'d-none d-sm-table-cell'}>Name</th>
                    <th className={'d-none d-sm-table-cell'}>Country</th>
                    <th className={'d-none d-sm-table-cell'}>Coordinates</th>
                </tr>
            </thead>
            {paginatedAirports?.map((airport) => {
                return (
                    <tbody key={`tbody_${airport.airport_id}`}>
                        <tr key={`row_1_${airport.airport_id}`} onClick={() => showAirportDetails(airport)}>
                            <td key={`icao_code_${airport.icao_code}`} rowSpan="2">{airport.icao_code}</td>
                            <td key={`iata_code_${airport.iata_code}`}>{airport.iata_code}</td>
                            <td key={`fir_${airport.fir}`}>{airport.fir}</td>
                            <td key={`name_${airport.name}`}>{airport.name}</td>
                            <td key={`country_${airport.country}`}>{airport.country}</td>
                            <td key={`coordinates_${airport.coordinates}`}>{airport.coordinates}</td>
                        </tr>
                    </tbody>
                )
            })}
            <AirportModal
                show={detailModalShow}
                onHide={() => setDetailModalShow(false)}
                airportDetail={airportDetail}
                onEdit={showEditModal}
                onDelete={onAirportDelete}
            />
            <AirportModalEdit
                show={editModalShow}
                onHide={() => setEditModalShow(false)}
                airportDetail={airportDetail}
                onAirportUpdate={onAirportUpdate}
            />
        </table>
    )
}
