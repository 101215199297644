import Form from 'react-bootstrap/Form';

export default function OptionSwitch({group, selection, setOption, value}) {

    const selectOnlyOneCheckbox = (targetCheckbox) => {
        let checkboxes = document.querySelectorAll('[id^='+group+'_]');

        if (checkboxes.length !== 1) {
            Array.prototype.forEach.call(checkboxes,function(check){
                if (check.id.includes(group)) {
                    check.checked = false;
                }
            });
            targetCheckbox.checked = true;
        }
    };

    return (
        <Form.Switch>
            {selection.map((option, index) => {
                return (
                    <Form.Check
                        key={index}
                        id={group+'_'+index}
                        type="switch"
                        label={option}
                        value={option}
                        onChange={(e) => {
                            selectOnlyOneCheckbox(e.target);
                            setOption(e.target);
                        }}
                        defaultChecked={(value !== undefined && (value === option || value === true))}
                    />
                );
            })}
        </Form.Switch>
    );
};
