import useAuth from '../hooks/useAuth';
import {useEffect, useState, useRef} from 'react';
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import AirportFilter from './airport/AirportFilter';
import AirportTable from './airport/AirportTable';
import Pagination from './Pagination';
import {GetAirports} from './api-requests/Requests';
import Button from 'react-bootstrap/Button';
import {PlusCircle} from 'react-bootstrap-icons';
import AirportModalAdd from './airport/AirportModalAdd';

const Airport = () => {
    const { auth, loading } = useAuth();

    const [paginatedAirports, setPaginatedAirports] = useState();

    const loadAirports = useRef(true);
    const [addAirportModalShow, setAddAirportModalShow] = useState(false);

    const [airports, setAirports] = useState([]);
    const [gridAirports, setGridAirports] = useState([]);

    const [pageSize, setPageSize] = useState(10);

    const GetAllAirports = () => {
        // eslint-disable-next-line no-lone-blocks
        {GetAirports(function(response) {
            setAirports(response);
            setGridAirports(response);
            setPaginatedAirports(_(response).slice(0).take(pageSize).value());
        })}
    }

    const addAirport = () => {
        setAddAirportModalShow(true);
    }

    useEffect(() => {
        if (loadAirports.current) {
            loadAirports.current = false;
            // eslint-disable-next-line no-lone-blocks
            { GetAllAirports() }
        }
        // eslint-disable-next-line
    }, [])

    if (loading) {
        return (
            <section>
                <h1>Loading...</h1>
            </section>
        )
    }
    return (
        <section className={'section-authorized'}>
            <h1>Airport management</h1>
            <br />
            <p>Welcome {auth.givenName}</p>

            <div className={'container mt-3'}>
                <AirportFilter
                    airports={airports}
                    pageSize={pageSize}
                    setGridAirports={(grid_airports) => setGridAirports(grid_airports)}
                    setPaginatedAirports={(paginated_airports) => setPaginatedAirports(paginated_airports)}
                />
                <Button variant="success" className={'addButton'} onClick={addAirport}>
                    <PlusCircle size={18}/>
                </Button>
                <AirportTable
                    paginatedAirports={paginatedAirports}
                    onAirportDelete={GetAllAirports}
                    onAirportUpdate={GetAllAirports}
                />
                <Pagination
                    data={gridAirports}
                    pageSize={pageSize}
                    setPageSize={(page_size) => setPageSize(page_size)}
                    setPaginatedItems={(paginated_airports) => setPaginatedAirports(paginated_airports)}
                />
                <AirportModalAdd
                    show={addAirportModalShow}
                    onHide={() => setAddAirportModalShow(false)}
                    onAirportAdd={GetAllAirports}
                    loadFir={addAirportModalShow}
                />
            </div>
        </section>
    )
}

export default Airport
