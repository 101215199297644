import {useState, useEffect} from 'react';
import _ from 'lodash';

export default function FirtFilter({firs, pageSize, setGridFirs, setPaginatedFirs}) {
    const [NameFilter, setNameFilter] = useState();
    const [CountryFilter, setCountryFilter] = useState();

    useEffect(() => {
        let initialFirs = [];
        firs.forEach(item => {
            initialFirs.push(item.fir_id);
        });

        setNameFilter(initialFirs);
        setCountryFilter(initialFirs);
    }, [firs])

    const refreshFilteredFirs = (nameFiltered, countryFiltered) => {
        // eslint-disable-next-line array-callback-return
        let filteredFirs = firs.filter((item) => {
            if (nameFiltered.includes(item.fir_id) && countryFiltered.includes(item.fir_id)) {
                return item;
            }
        });

        setGridFirs(filteredFirs);
        let paginatedFirs = _(filteredFirs).slice(0).take(pageSize).value();
        setPaginatedFirs(paginatedFirs);
    }

    const SetNameFilter = (e) => {
        let filteredFirs = [];

        firs.forEach(item => {
            if (item.fir.includes(e.target.value.toString().toUpperCase())) {
                filteredFirs.push(item.fir_id);
            }
        });

        setNameFilter(filteredFirs);
        refreshFilteredFirs(filteredFirs, CountryFilter);
    }

    const SetCountryFilter = (e) => {
        let filteredFirs = [];

        firs.forEach(item => {
            if (item.country.includes(e.target.value)) {
                filteredFirs.push(item.fir_id);
            }
        });

        setCountryFilter(filteredFirs);
        refreshFilteredFirs(NameFilter, filteredFirs);
    }

    return (
        <div style={{width: '100%'}} className={'d-flex'}>
             <nav className={'d-flex justify-content-left'} style={{width: '100%'}}>
                 <nav className={'filter-section'}>
                     <p>Name</p>
                     <input
                         type='text'
                         onChange={SetNameFilter}
                         className={'filterInput-m'}
                     />
                 </nav>
                 <nav className={'filter-section'}>
                     <p>Country</p>
                     <input
                         type='text'
                         onChange={SetCountryFilter}
                         className={'filterInput-xl'}
                     />
                 </nav>
             </nav>
         </div>
     )
}
