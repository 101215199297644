import _ from 'lodash';
import DatePicker from 'react-datepicker';
import Button from 'react-bootstrap/Button';

export default function DateFilter(
    {
        allData,
        startDate,
        endDate,
        pageSize,
        setData,
        setGridData,
        setPaginatedData,
        setStartDate,
        setEndDate
    }) {
    const SetDateFilter = () => {
        // eslint-disable-next-line array-callback-return
        let filteredByDate = allData.filter((item) => {
            let [day, month, year] = item.departure_date.split('.'),
                date               = new Date(year, month -1, day);

            if (date >= startDate) {
                return item;
            }
        });

        // eslint-disable-next-line array-callback-return
        filteredByDate = filteredByDate.filter((item) => {
            let [day, month, year] = item.departure_date.split('.'),
                date               = new Date(year, month -1, day);
            if (date <= endDate) {
                return item;
            }
        });

        setData(filteredByDate);
        setGridData(filteredByDate);
        let paginatedData = _(filteredByDate).slice(0).take(pageSize).value();
        setPaginatedData(paginatedData);
    }

    return (
        <div style={{width: '100%'}} className={'d-flex'}>
            <nav className={'d-flex justify-content-left'} style={{width: '100%'}}>
                <nav className={'filter-section'}>
                    <p>Startdate</p>
                    <DatePicker
                        dateFormat="dd.MM.yy"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        className={'filterInput-m'}
                    />
                </nav>
                <nav className={'filter-section'}>
                    <p>Enddate</p>
                    <DatePicker
                        dateFormat="dd.MM.yy"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        className={'filterInput-m'}
                    />
                </nav>
                <nav className={'filter-section'} style={{minWidth: '145px'}}>
                    <Button onClick={SetDateFilter} variant="primary">
                        Apply date-filter
                    </Button>
                </nav>
            </nav>
        </div>
    )
}
