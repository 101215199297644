export function filterAircraftsByValue(aircrafts, value, _callback) {
    let filteredAircrafts = [];

    aircrafts.forEach(item => {
        if (item.registration.includes(value)) {
            filteredAircrafts.push([
                item.aircraft_id,
                item.registration + ' ('+item.icao_type+')'
            ]);
        }
    });

    _callback(filteredAircrafts);
}
