const NoSubscription = () => {
    return (
        <section className={'section-unauthorized'}>
            <h1>No subscription</h1>
            <br />
            <p>You do not have a subscription. Please contact sandro@aebischer.one</p>
        </section>
    )
}

export default NoSubscription
