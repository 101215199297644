import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import axios from '../api/axios';
import useAuth from '../hooks/useAuth';
import {Link, useNavigate} from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap'

const LogplusNavbar = () => {
    const { setAuth, auth, loading } = useAuth();
    const navigate = useNavigate();

    const LOGOUT_URL = '/logout';

    if (loading) {
        return;
    }

    const logout = async () => {
        await axios.post(LOGOUT_URL,
            JSON.stringify(),
            {
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                    'Accept': 'application/json'
                },
                withCredentials: true
            }
        );
        localStorage.clear();
        setAuth({});
        navigate('/login');
    }

    if (auth.email) {
        return (
            <Navbar collapseOnSelect expand="lg"  variant="dark" className={'nav-bar'}>
                <Container>
                    <Navbar.Brand as={Link} to='/'>logplus</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <LinkContainer to="/">
                                <Nav.Link className={'nav-link'}>Logbook</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/logbookEntry">
                                <Nav.Link className={'nav-link'}>New Flight</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/aircraft" className={auth.isAdmin === 0 ? "offscreen" : ""}>
                                <Nav.Link className={'nav-link'}>Aircrafts</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/airport" className={auth.isAdmin === 0 ? "offscreen" : ""}>
                                <Nav.Link className={'nav-link'}>Airport</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/fir" className={auth.isAdmin === 0 ? "offscreen" : ""}>
                                <Nav.Link className={'nav-link'}>FIR</Nav.Link>
                            </LinkContainer>
                        </Nav>
                        <Nav>
                            <Nav.Link id={'navbar_username'}>{auth.givenName+' '+auth.lastName}</Nav.Link>
                            <Nav.Link onClick={logout}>Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    } else {
        return (
            <Navbar collapseOnSelect expand="lg"  variant="dark" className={'nav-bar'}>
                <Container>
                    <Navbar.Brand>logplus</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav>
                            <Nav.Link onClick={logout}>Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default LogplusNavbar;
